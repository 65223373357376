import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Router, Routes, Route } from "react-router-dom";
import Home from "../src/Components/Home/Home";
import Headertop from "./Components/Header/Headertop/Headertop";
import Newheader from "./Components/Newheader/Newheader";
import About from "./Components/About/About";
import Footer from "./Components/Footer/footer";
import Doctor from "./Components/Doctor/Doctor";
import PilesTreatments from "./Components/Treatment/Pilestreatments/PilesTreatments";
import Pilessurgery from "./Components/Treatment/Pilessurgery/Pilessurgery";
import Analfissures from "./Components/Treatment/Analfissures/Analfissures";
import Analpolyps from "./Components/Treatment/Analpolyps/Analpolyps";
import FistulaInAno from "./Components/Treatment/Fistula-in-ano/Fistula-in-ano";
import Rectalprolapse from "./Components/Treatment/Rectalprolapse/Rectalprolapse";
import Perianalabscess from "./Components/Treatment/Perianalabscess/Perianalabscess";
import Analskintags from "./Components/Treatment/Analskintags/Analskintags";
import Constipation from "./Components/Treatment/Constipation/Constipation";
import IBS from "./Components/Treatment/IBS/IBS";
import Fistulasurgery from "./Components/Treatment/Fistulasurgery/Fistulasurgery";
import Fistulatreatment from "./Components/Treatment/Fistulatreatment/Fistulatreatment";
import Hemorrhoidstreatment from "./Components/Treatment/Hemorrhoidstreatment/Hemorrhoidstreatment";
import Endoscopic from "./Components/Treatment/Endoscopic/Endoscopic";
import MIPH from "./Components/Treatment/MIPH/MIPH";
import Laser from "./Components/Treatment/Laser/Laser";
import Anal from "./Components/Treatment/Anal/Anal";
import Chroniculcer from "./Components/Treatment/Chroniculcer/Chroniculcer";
import Infertility from "./Components/Treatment/Infertility/Infertility";
import Circumcision from "./Components/Treatment/Circumcision/Circumcision";
import Sexproblems from "./Components/Treatment/Sexproblems/Sexproblems";
import Contact from "./Components/Contact/Contact";
import Blogpage from "./Components/Blogpage/Blogpage";
import Gallery from "./Components/Gallery/Gallery";
import Appointment from "./Components/Appoitmenter/Appointment";
import Singledoctor from "./Components/Singledoctor/Singledoctor";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Appointment />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/blog" element={<Blogpage />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/doctor" element={<Doctor />}></Route>
          <Route path="/pilestreatments" element={<PilesTreatments />}></Route>
          <Route path="/pilessurgery" element={<Pilessurgery />}></Route>
          <Route path="/analfissures" element={<Analfissures />}></Route>
          <Route path="/analpolyps" element={<Analpolyps />}></Route>
          <Route path="/fistulaInano" element={<FistulaInAno />}></Route>
          <Route path="/rectalprolapse" element={<Rectalprolapse />}></Route>
          <Route path="/perianalabscess" element={<Perianalabscess />}></Route>
          <Route path="/analskintags" element={<Analskintags />}></Route>
          <Route path="/constipation" element={<Constipation />}></Route>
          <Route path="/ibs" element={<IBS />}></Route>
          <Route path="/fistulasurgery" element={<Fistulasurgery />}></Route>
          <Route
            path="/fistulatreatment"
            element={<Fistulatreatment />}
          ></Route>
          <Route
            path="/hemorrhoidstreatment"
            element={<Hemorrhoidstreatment />}
          ></Route>
          <Route path="/endoscopic" element={<Endoscopic />}></Route>
          <Route path="/miph" element={<MIPH />}></Route>
          <Route path="/laser" element={<Laser />}></Route>
          <Route path="/anal" element={<Anal />}></Route>
          <Route path="/chroniculcer" element={<Chroniculcer />}></Route>
          <Route path="/infertility" element={<Infertility />}></Route>
          <Route path="/circumcision" element={<Circumcision />}></Route>
          <Route path="/sexproblems" element={<Sexproblems />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/gallery" element={<Gallery />}></Route>
          <Route path="/mujahidpasha" element={<Singledoctor />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
