import { memo } from "react"
import { Link } from "react-router-dom"

function Aboutbanner(){
    return (<>
      <section class="page-title page-title-layout1 bg-overlay piletratmentsbanner">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5">
              <h1 style={{color:"white"}} class="pagetitle__heading">About Us</h1>
              <p style={{color:"white"}} class="pagetitle__desc">Zain piles hospital is an advanced proctology centre with all types and systems of treatment for piles ,fissure,fistula in ano , pilonidal sinus and other conditions related to the ano rectal region.
              </p>
              <div class="d-flex flex-wrap align-items-center">
                <Link to="/" style={{backgroundColor:"#ffff00",color:"black"}} class="btn btn__primary btn__rounded mr-30">
                  <span>Home</span>
                  <i class="icon-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>)
}
export default memo(Aboutbanner)