import { memo } from "react"
import Servicelist from "../Servicelist"

function Pilestreatmentsser() {
    return (<>
    <section class="blog blog-single pb-80 pt-80">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-8">
            <div class="post-item mb-0">
              <div class="post__img">
                <a href="#">
                  <img className="w-100" src="../photo/x1.jpg" alt="post image" loading="lazy"/>
                </a>
              </div>
              <div  class="post__body pb-0">
                <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                  <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                </div>
                <h1 class="post__title mb-30">
                Piles Treatments
                </h1>
                <div class="post__desc">
                  <p>Piles, also known as hemorrhoids, are swollen and inflamed blood vessels in the anal and rectal area. They can cause discomfort, pain, itching, and bleeding. Fortunately, there are several effective treatments available for piles, ranging from lifestyle changes to medical interventions.</p>
                  <h6 style={{fontWeight:"bold"}}>Lifestyle modifications</h6>
                  <p>For mild cases of piles, lifestyle changes can be highly beneficial. These include consuming a fiber-rich diet to soften stools and reduce straining during bowel movements, staying hydrated, and avoiding prolonged sitting or standing. Regular exercise can also improve bowel movements and promote better blood circulation in the anal area.</p>
                  <h6 style={{fontWeight:"bold"}}>Over-the-counter medications</h6>
                  <p>Pharmacies offer various creams, ointments, and suppositories that can provide relief from itching and discomfort associated with piles. These topical treatments often contain ingredients like hydrocortisone or witch hazel, which help reduce inflammation and soothe the affected area.</p>
                  <h6 style={{fontWeight:"bold"}}>Sitz baths</h6>
                  <p>Soaking the anal area in warm water, known as a sitz bath, can ease the symptoms of piles. This practice helps reduce swelling and promote healing. Sitz baths can be done several times a day for around 10-15 minutes each time.</p>
                  <h6 style={{fontWeight:"bold"}}>Rubber band ligation</h6>
                  <p>A common medical procedure for treating internal piles is rubber band ligation. A doctor places a small rubber band around the base of the hemorrhoid, cutting off its blood supply. The piles then wither away and fall off within a few days. It is a minimally invasive outpatient procedure with a high success rate.</p>
                  <h6 style={{fontWeight:"bold"}}>Invasive procedures</h6>
                  <p>In more severe cases of piles, when other treatments haven't been effective, surgical options may be considered. Procedures like hemorrhoidectomy involve the surgical removal of the hemorrhoids. These surgeries are typically performed under anesthesia and require a recovery period.</p>
                  <p>In conclusion, piles can be a distressing condition, but there are several treatment options available to alleviate the symptoms and promote healing. Mild cases can often be managed with lifestyle changes and over-the-counter medications, while more severe cases may require medical interventions or surgical procedures. If you are experiencing symptoms of piles, it is essential to consult a healthcare professional for an accurate diagnosis and appropriate treatment plan.</p>
                </div>
              </div>
            </div>
          </div>
                <Servicelist/>
        </div>
      </div>
    </section>
    </>)
}
export default memo(Pilestreatmentsser)