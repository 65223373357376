import { memo } from "react";
import Map from "./Map";

function Contactform() {
  return (
    <>
      <section class="contact-layout1 pt-0">
        <div style={{ width: "100%" }} class="">
          <div class="row">
            <div class="col-12">
              <div class="contact-panel d-flex flex-wrap">
                <form
                  class="contact-panel__form"
                  method="post"
                  action="assets/php/contact.php"
                  id="contactForm"
                >
                  {/* <div class="row">
                  <div class="col-sm-12">
                    <h4 class="contact-panel__title">How Can We Help? </h4>
                    <p class="contact-panel__desc mb-30">Please feel welcome to contact our friendly reception staff
                      with any general or medical enquiry. Our doctors will receive or return any urgent calls.
                    </p>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <i class="icon-user form-group-icon"></i>
                      <input type="text" class="form-control" placeholder="Name" id="contact-name" name="contact-name"
                        required/>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <i class="icon-email form-group-icon"></i>
                      <input type="email" class="form-control" placeholder="Email" id="contact-email"
                        name="contact-email" required/>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <i class="icon-phone form-group-icon"></i>
                      <input type="text" class="form-control" placeholder="Phone" id="contact-Phone"
                        name="contact-phone" required/>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <i class="icon-news form-group-icon"></i>
                      <select style={{height:"60px"}} class="form-control">
                        <option value="0">Subject</option>
                        <option value="1">Subject 1</option>
                        <option value="2">Subject 1</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <i class="icon-alert form-group-icon"></i>
                      <textarea style={{height:"200px"}} class="form-control" placeholder="Message" id="contact-message"
                        name="contact-message"></textarea>
                    </div>
                    <button style={{backgroundColor:"#ffff00",borderRadius:"8px"}} type="submit" class="p-4 w-100 mt-10">
                      <span>Submit Request</span> <i class="icon-arrow-right"></i>
                    </button>
                    <div class="contact-result"></div>
                  </div>
                </div> */}
                  <Map />
                </form>
                <div></div>
                <div
                  style={{ backgroundColor: "#7b0021" }}
                  class="contact-panel__info d-flex flex-column justify-content-between  "
                >
                  <div style={{ backgroundColor: "#7b0021" }} class="bg-img">
                    <img src="" alt="banner" />
                  </div>
                  <div>
                    <h4 class="contact-panel__title color-white">
                      Quick Contacts
                    </h4>
                    <p class="contact-panel__desc font-weight-bold color-white mb-30">
                      Please feel free to contact our friendly staff with any
                      medical enquiry.
                    </p>
                  </div>
                  <div>
                    <ul class="contact__list list-unstyled mb-30">
                      <li>
                        <i class="icon-phone"></i>
                        <a
                          style={{ textDecoration: "none" }}
                          href="tel:8867724886"
                        >
                          Emergency Line: 8867724886
                        </a>
                      </li>
                      <li>
                        <i class="icon-phone"></i>
                        <a
                          style={{ textDecoration: "none" }}
                          href="tel:9480394694"
                        >
                          Emergency Line: 9480394694
                        </a>
                      </li>
                      <li>
                        <i class="icon-location"></i>
                        <a style={{ textDecoration: "none" }} href="#">
                          Location: Banaswadi, Bangalore, Karnataka
                        </a>
                      </li>
                      <li>
                        <i class="icon-clock"></i>
                        <a style={{ textDecoration: "none" }} href="#">
                          Mon - Fri: 11:00 am - 8:00 pm
                        </a>
                      </li>
                    </ul>
                    <a
                      href="https://click4appointment.com/book-appointment-guest/502168/3326"
                      class="btn btn__white btn__rounded btn__outlined"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default memo(Contactform);
