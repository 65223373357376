import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Circumcisionbanner from "./Circumcisionbanner"
import Circumcisionser from "./Circumcisionser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function Circumcision(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <Circumcisionbanner/>
    <Circumcisionser/>
    <Footer/>
    </>)
}
export default memo(Circumcision)