import React from "react";

function Appointment() {
  return (
    <>
      <div className="appoitmenter w-100">
        <div className="w-100 buttonetrtr">
          <a
            className="iconin-box"
            href="https://wa.me/9480394694?text=Hello, How can I help you??"
          >
            <img src="../photo/whatsapp.png" alt="" />
          </a>
          <a className="iconin-box" href="tel:8867724886">
            <img src="../photo/telephone.png" alt="" />
          </a>
        </div>
        <div className="w-100 backolor pb-2 pt-2">
          <a
            href="https://click4appointment.com/book-appointment-guest/502168/3326"
            style={{ backgroundColor: "#f4365f", textDecoration: "none" }}
            class="btn btn__primary btn__rounded ml-30 widthers"
          >
            <i class="icon-calendar"></i>
            <span>Appointment</span>
          </a>
        </div>
      </div>
    </>
  );
}

export default Appointment;
