import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Analbanner from "./Analbanner"
import Analser from "./Analser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function Anal(){
    useEffect(()=>{
        autotoper()
    },[])
    return (<>

    <Newheader/>
    <Analbanner/>
    <Analser/>
    <Footer/>
    </>)
}
export default memo(Anal)