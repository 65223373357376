import { memo, useEffect } from "react";
import Newheader from "../../Newheader/Newheader";
import IBSbanner from "./IBSbanner";
import IBSser from "./IBSser";
import Footer from "../../Footer/footer";
import { autotoper } from "../../Mainfunction";

function IBS(){
    useEffect(()=>{
        autotoper()
    },[])
    return (<>
    <Newheader/>
    <IBSbanner/>
    <IBSser/>
    <Footer/>
    </>)
}
export default memo(IBS)