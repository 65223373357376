import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Fistulasurgerybanner from "./Fistulasurgerybanner"
import Fistulasurgeryser from "./Fistulasurgeryser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function Fistulasurgery(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <Fistulasurgerybanner/>
    <Fistulasurgeryser/>
    <Footer/>
    </>)
}
export default memo(Fistulasurgery)