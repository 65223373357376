import { memo } from "react"
import Servicelist from "../Servicelist"

function Infertilityser(){
    return (<>
        <section class="blog blog-single pb-80 pt-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="post-item mb-0">
                  <div class="post__img">
                    <a href="#">
                      <img className="w-100" src="../photo/p19.jpg" alt="post image" loading="lazy"/>
                    </a>
                  </div>
                  <div  class="post__body pb-0">
                    <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                      <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                    </div>
                    <h1 class="post__title mb-30">
                    Infertility
                    </h1>
                    <div class="post__desc">
                      <p>
Infertility due to piles (hemorrhoids) is a rare occurrence. Hemorrhoids are swollen and inflamed veins in the rectum or anus, and they usually do not directly affect fertility. However, in some cases, chronic and severe piles can lead to certain factors that may indirectly impact fertility.</p>
<h6><strong>Pain and Discomfort</strong></h6>
<p> Severe piles can cause significant pain and discomfort, especially during bowel movements and daily activities. This discomfort may affect a person's sexual desire or ability to engage in sexual activities, potentially leading to reduced frequency of intercourse.</p>
<h6><strong>Psychological Impact</strong></h6>
<p>Chronic piles can cause distress and anxiety, affecting a person's mental health. Psychological stress and anxiety can contribute to sexual dysfunction or reduced libido, indirectly affecting fertility.</p>
<p>It's essential to remember that infertility due to piles is uncommon, and many individuals with piles do not experience fertility problems. However, if you have concerns about your fertility or any related symptoms, it is essential to consult a healthcare professional. They can assess your specific situation, provide appropriate advice, and offer treatments to manage piles effectively, potentially reducing any indirect impact on fertility. If you are actively trying to conceive and facing infertility issues, a thorough evaluation by a fertility specialist is recommended to identify and address any underlying factors contributing to the problem.</p>
                    </div>
                  </div>
                </div>
              </div>
              
                    <Servicelist/>
            </div>
          </div>
        </section>
    </>)
}
export default memo(Infertilityser)