import { memo } from "react"
import Servicelist from "../Servicelist"

function Fistulasurgeryser(){
    return(<>
        <section class="blog blog-single pb-80 pt-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="post-item mb-0">
                  <div class="post__img">
                    <a href="#">
                      <img className="w-100" src="../photo/p11.jpg" alt="post image" loading="lazy"/>
                    </a>
                  </div>
                  <div  class="post__body pb-0">
                    <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                      <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                    </div>
                    <h1 class="post__title mb-30">
                    Fistula Surgery
                    </h1>
                    <div class="post__desc">
                      <p>Fistula surgery is a medical procedure performed to treat a fistula, an abnormal tract or tunnel that forms between two organs or between an organ and the skin. Fistulas can occur in various parts of the body, but one common type is an anal fistula, which involves an abnormal tunnel between the anal canal or rectum and the skin surrounding the anus. Fistulas often develop as a complication of an abscess that didn't heal properly.</p>
                      <p>The primary goal of fistula surgery is to eliminate the abnormal tract and promote healing. There are different surgical approaches to treat fistulas, and the choice of procedure depends on the location, complexity, and severity of the fistula.</p>
                      <p>One common surgical technique is a fistulotomy, where the surgeon makes an incision along the length of the fistula to open and expose the tract. This allows the fistula to heal from the inside out. Fistulotomy is effective for simple, low-lying fistulas.</p>
                      <p>In more complex cases, such as fistulas that involve multiple tracts or are close to sphincter muscles, other procedures may be used. These can include the placement of a seton, which is a piece of thread or rubber, through the fistula tract to aid drainage and gradual healing. Advancement flap procedures involve using healthy tissue from the rectal wall to close the external opening of the fistula.</p>
                      <p>Fistula surgery is usually performed under general or local anesthesia, depending on the complexity and patient's health condition. After the surgery, patients are advised to follow post-operative instructions to ensure proper wound healing and to minimize the risk of complications. It's essential for patients to maintain good anal hygiene and attend follow-up appointments to monitor healing progress.</p>
                      <p>Fistula surgery can be highly effective in resolving fistula-related symptoms and preventing recurrent infections. However, as with any surgical procedure, there are potential risks and complications, and it's crucial for patients to have a thorough discussion with their healthcare provider to understand the benefits and risks before proceeding with the surgery.</p>
                    </div>
                  </div>
                </div>
              </div>
              
                    <Servicelist/>
            </div>
          </div>
        </section>
    </>)
}
export default memo(Fistulasurgeryser)