import { memo } from "react";
import LazyLoad from "react-lazy-load";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Galleryhome() {
  return (
    <>
      <section class="blog-grid pb-50">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
              <div class="heading text-center mb-40">
                <h1 class="section-title section-title_60">
                  OUR <span>Blogs</span>
                </h1>
                <h2
                  style={{ lineHeight: "22px", color: "#7b0021" }}
                  class="heading__subtitle"
                >
                  To help prevent or manage piles (also known as hemorrhoids),
                  it's important to follow a balanced diet that promotes healthy
                  digestion and regular bowel movements. Here are some dietary
                  guidelines to consider
                </h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
              <div class="post-item">
                <div class="post__img">
                  <a href="#">
                    <img src="photo/aaa1.jpg" alt="post image" loading="lazy" />
                  </a>
                </div>
                <div class="post__body">
                  <div
                    style={{ backgroundColor: "yellow" }}
                    class="post__meta-cat"
                  >
                    <a
                      style={{
                        textDecoration: "none",
                        backgroundColor: "yellow",
                        color: "red",
                      }}
                      href="#"
                    >
                      ZAIN PILES HOSPITAL
                    </a>
                  </div>
                  <h4 class="post__title">Increase fiber intake</h4>

                  <p class="post__desc">
                    Consuming an adequate amount of fiber helps regulate bowel
                    movements and prevents constipation, which can worsen piles.
                    Include high-fiber foods such as whole grains, fruits,
                    vegetables, legumes, and nuts in your diet.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
              <div class="post-item">
                <div class="post__img">
                  <a href="#">
                    <img src="photo/aaa2.jpg" alt="post image" loading="lazy" />
                  </a>
                </div>
                <div class="post__body">
                  <div
                    style={{ backgroundColor: "yellow" }}
                    class="post__meta-cat"
                  >
                    <a
                      style={{
                        textDecoration: "none",
                        backgroundColor: "yellow",
                        color: "red",
                      }}
                      href="#"
                    >
                      ZAIN PILES HOSPITAL
                    </a>
                  </div>
                  <h4 class="post__title">Stay hydrated</h4>
                  <p class="post__desc">
                    Drink plenty of water throughout the day to soften the stool
                    and ease bowel movements. Aim for at least 8 glasses of
                    water daily, but individual needs may vary.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
              <div class="post-item">
                <div class="post__img">
                  <a href="#">
                    <img src="photo/aaa3.jpg" alt="post image" loading="lazy" />
                  </a>
                </div>
                <div class="post__body">
                  <div
                    style={{ backgroundColor: "yellow" }}
                    class="post__meta-cat"
                  >
                    <a
                      style={{
                        textDecoration: "none",
                        backgroundColor: "yellow",
                        color: "red",
                      }}
                      href="#"
                    >
                      ZAIN PILES HOSPITAL
                    </a>
                  </div>
                  <h4 class="post__title">Limit processed foods</h4>

                  <p class="post__desc">
                    Processed foods are typically low in fiber and high in
                    unhealthy fats, additives, and preservatives. Reduce your
                    intake of processed snacks, fast food, packaged meals, and
                    sugary treats.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default memo(Galleryhome);
