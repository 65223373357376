import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Endoscopicbanner from "./Endoscopicbanner"
import Endoscopicser from "./Endoscopicser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function Endoscopic(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <Endoscopicbanner/>
    <Endoscopicser/>
    <Footer/>
    </>)
}
export default memo(Endoscopic)