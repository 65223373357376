import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Analskintagsbanner from "./Analskintagsbanner"
import Analfissuresser from "../Analfissures/Analfissuresser"
import Footer from "../../Footer/footer"
import Analskintagsser from "./Analskintagsser"
import { autotoper } from "../../Mainfunction"

function Analskintags(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <Analskintagsbanner/>
    <Analskintagsser/>
    <Footer/>
    </>)
}
export default memo(Analskintags)