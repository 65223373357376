import React from "react";

function Singledoc() {
  return (
    <>
      <section
        style={{ padding: "50px 0px", position: "relative" }}
        class="form"
      >
        <div style={{ padding: "50px 0px" }} class="container">
          <div className="row">
            <div class="col-lg-3 col-12">
              <div style={{ position: "sticky", top: "0px" }}>
                <img
                  style={{
                    marginBottom: "30px",
                  }}
                  src="photo/sjbhdv.jpg"
                />
              </div>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", padding: "20px" }}
              class="col-lg-9 col-12"
            >
              <div>
                <h1 class="section-title">
                  <span>Dr. MUJAHID PASHA M</span>
                </h1>
                <div>
                  <h5 style={{ color: "#f22283" }}>
                    Piles Specialist , Family Physician , Proctologist <br />{" "}
                    Cosmetologist & Ayurvedic Consultant{" "}
                  </h5>
                </div>
                <div>
                  <p style={{ color: "#7b0021" }}>
                    MBBS, BAMS, PGDCC, FAAM, CCKT, Fellowship in
                    Gastroenterology.
                  </p>
                </div>
                <p style={{ textAlign: "justify" }} class="subtitle">
                  Dr. Mujahid Pasha M is one of the few doctors in India with
                  MBBS & BAMS, PGDCC, FAAM, CCKT, Fellowship in Gastroenterology
                  and can treat piles and other patients Allopathic medicines ,
                  surgery or ayurvedic traditional healing and treatments.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Dr MUJAHID PASHA,who is well versed in Ayurvedic as well as
                  Allopathic medical science due to his dual degrees BAMS,MBBS .
                  Also has done his additional course for fistula treatment that
                  is KSHARA SUTRA THERAPY .
                </p>
                <h5 style={{ color: "#f22283" }}>
                  Meet Dr. Mujahid Pasha M - Best Piles Specialist , Piles
                  Surgeon , Laser Piles Treatment , Piles Treatment Without
                  Surgery , Ayurvedic Piles Treatment in Bangalore , Karnataka ,
                  India .
                </h5>
                <p style={{ textAlign: "justify", marginBottom: "20px" }}>
                  Dr. Mujahid Pasha M is a distinguished medical professional,
                  excelling as a Piles Specialist, Family Physician,
                  Proctologist, Cosmetologist, and Ayurvedic Consultant. His
                  extensive qualifications, including MBBS, BAMS, PGDCC, FAAM,
                  CCKT, and a Fellowship in Gastroenterology has cured thousands
                  of patients suffering from piles diseases which makes him a
                  rare and versatile practitioner in India.
                </p>
                <h5 style={{ color: "#f22283" }}>
                  Achievements for Dr. Mujahid Pasha M:
                </h5>
                <p style={{ textAlign: "justify" }}>
                  Dr. Mujahid Pasha M is one of the few doctors in India with
                  MBBS & BAMS, PGDCC, FAAM, CCKT, Fellowship in Gastroenterology
                  and can treat piles and other patients Allopathic medicines ,
                  surgery or ayurvedic traditional healing and treatments. Dr
                  MUJAHID PASHA,who is well versed in Ayurvedic as well as
                  Allopathic medical science due to his dual degrees BAMS,MBBS .
                  Also has done his additional course for fistula treatment that
                  is KSHARA SUTRA THERAPY
                </p>
                <h5 style={{ color: "#f22283" }}>
                  Comprehensive Piles Healthcare Treatments in Bangalore :
                </h5>
                <div>
                  <ul style={{ paddingLeft: "20px", marginTop: "20px" }}>
                    <li>
                      <div>
                        <h6
                          style={{
                            fontSize: "18px",
                            color: "#1f3d9d",
                            fontWeight: "600",
                          }}
                        >
                          Piles Specialist:{" "}
                        </h6>

                        <p>
                          Offering a spectrum of treatments for piles, Dr. Pasha
                          employs a holistic approach, providing relief through
                          Allopathic medicines, surgical interventions, and the
                          ancient wisdom of Ayurveda.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div>
                        <h6
                          style={{
                            fontSize: "18px",
                            color: "#1f3d9d",
                            fontWeight: "600",
                          }}
                        >
                          Family Physician:{" "}
                        </h6>

                        <p>
                          Your family's well-being is in expert hands. Dr. Pasha
                          is dedicated to delivering personalized and
                          comprehensive healthcare services for every member of
                          your family.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div>
                        <h6
                          style={{
                            fontSize: "18px",
                            color: "#1f3d9d",
                            fontWeight: "600",
                          }}
                        >
                          Proctologist:{" "}
                        </h6>

                        <p>
                          Specializing in proctology, Dr. Pasha addresses
                          various rectal and anal conditions with a focus on
                          patient comfort and successful outcomes.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div>
                        <h6
                          style={{
                            fontSize: "18px",
                            color: "#1f3d9d",
                            fontWeight: "600",
                          }}
                        >
                          Cosmetologist:{" "}
                        </h6>

                        <p>
                          Experience the perfect blend of medical expertise and
                          aesthetic sensibility with Dr. Pasha's cosmetic
                          solutions tailored to enhance your natural beauty.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div>
                        <h6
                          style={{
                            fontSize: "18px",
                            color: "#1f3d9d",
                            fontWeight: "600",
                          }}
                        >
                          Ayurvedic Consultant:{" "}
                        </h6>

                        <p>
                          Pasha's dual degrees in BAMS and MBBS uniquely
                          position him to integrate Ayurvedic principles into
                          your healthcare, providing holistic consultations and
                          traditional Ayurvedic treatments.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <h5 style={{ color: "#f22283" }}>
                  Advanced Medical Credentials and Specializations
                </h5>
                <div>
                  <ul style={{ paddingLeft: "20px", marginTop: "20px" }}>
                    <li>
                      <div>
                        <h6
                          style={{
                            fontSize: "18px",
                            color: "#1f3d9d",
                            fontWeight: "600",
                          }}
                        >
                          Fellowship in Gastroenterology:{" "}
                        </h6>

                        <p>
                          Stay at the forefront of gastrointestinal care with
                          Dr. Pasha's Fellowship in Gastroenterology, ensuring
                          access to cutting-edge treatments and interventions.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div>
                        <h6
                          style={{
                            fontSize: "18px",
                            color: "#1f3d9d",
                            fontWeight: "600",
                          }}
                        >
                          Kshara Sutra Therapy:{" "}
                        </h6>

                        <p>
                          Dr. Pasha's commitment to patient well-being is
                          evident in his additional training in Kshara Sutra
                          Therapy, a specialized Ayurvedic treatment for
                          fistula.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <h5 style={{ color: "#f22283" }}>Holistic Healing Approach</h5>
                <p style={{ textAlign: "justify", marginBottom: "20px" }}>
                  Dr. Mujahid Pasha M is adept in both Ayurvedic and Allopathic
                  medical sciences, providing you with a unique, holistic
                  healing experience for patients suffering from piles . His
                  comprehensive understanding allows for personalized piles
                  treatment plans that align with your preferences and needs.
                </p>
                <h5 style={{ color: "#f22283" }}>Schedule an Appointment</h5>
                <p style={{ textAlign: "justify" }}>
                  Embark on your journey to optimal health by scheduling an
                  appointment with Dr. Mujahid Pasha M. Our caring and dedicated
                  team is here to assist you every step of the way and get
                  complete relief from piles diseases .
                </p>
                <p style={{ textAlign: "justify", marginBottom: "30px" }}>
                  Choose excellence, choose integrated piles treatments - Choose
                  Dr. Mujahid Pasha M.
                </p>
                <a
                  href="https://click4appointment.com/book-appointment-guest/502168/3326"
                  style={{
                    backgroundColor: "#f22283",
                    padding: "10px 20px",
                    textDecoration: "none",
                    color: "white",
                    borderRadius: "20px",
                  }}
                  className="appointment"
                >
                  Book an appointment
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Singledoc;
