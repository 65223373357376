import { memo, useEffect, useState } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./Hospitalinfo.css"
import { Link } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"

function Hospitalinfo(){
    return (<>
   {/* <section class="about-layout2 pb-0">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-7 offset-lg-1">
            <div class="heading-layout2">
              <h3 class="heading__title mb-60"><strong>ZAIN PILES HOSPITAL</strong></h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-5">
            <div class="text-with-icon">
              <div class="text__icon">
                <i style={{color:"#7b0021"}} class="icon-doctor"></i>
              </div>
              <div class="text__content">
                <p class="heading__desc font-weight-bold mb-30"  style={{color:"#7b0021"}}>Our goal is to deliver quality of care
                  in a courteous, respectful, and
                  compassionate manner. We hope you will allow us to care for you and strive to be the first and best
                  choice for healthcare.
                </p>
                <a style={{width:"200px",backgroundColor:"yellow",color:"red"}} href="doctors-timetable.html" class="btn btn__secondary btn__rounded mb-70 btn__primarya">
                  <span>Find A Doctor</span> <i class="icon-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="video-banner-layout2 bg-overlay">
              <video style={{height:"auto",width:"100%"}} autoPlay controls  src="photo/large-intestine-and-digestive-system-2022-07-21-07-22-26-utc.mov">
                
              </video>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-7">
            <div class="about__text bg-white">
              <p class="heading__desc mb-30">We, Zain Piles & Skin Hospital situated at Banaswadi, Bangalore, Karnataka, are a well acclaimed piles Hospital providing a range of piles and other treatments. Our hospital is honoured to have provided care to over thousands of patients till date, including patients that visit us each week from around the city. We are the very few hospitals in India that treat piles through medicines , surgery and ayurvedic treatments . Our one of the doctors Dr. Mujahid Pasha M is one of the few doctors in india who has done both MBBS & BAMS , PGDCC, FAAM , CCKT , Fellowship in Gastroenterology and can treat piles patients through medicines , surgery or ayurvedic traditional healing and treatments.
              </p>
              <p class="heading__desc mb-30">We understand your problems because that's all we do in our practice. It's what we see every day. It's what we research. It's what we teach. This area of medicine is our calling and we want to beat it as much as our patients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    <section class="about-3" id="about">
            <h1 class="bg-text bg-text_dark">About us</h1>
            <div class="container">

                <div class="content">
                    <h4>who we are</h4>
                    <h1 class="section-title section-title_60">ZAIN PILES<span> HOSPITAL</span> </h1>
                    <p style={{textAlign:"justify"}}>We, Zain Piles & Skin Hospital situated at Banaswadi, Bangalore, Karnataka, are a well acclaimed piles Hospital providing a range of piles and other treatments. Our hospital is honoured to have provided care to over thousands of patients till date, including patients that visit us each week from around the city. We are the very few hospitals in India that treat piles through medicines , surgery and ayurvedic treatments . Our one of the doctors Dr. Mujahid Pasha M is one of the few doctors in india who has done both MBBS & BAMS , PGDCC, FAAM , CCKT , Fellowship in Gastroenterology and can treat piles patients through medicines , surgery or ayurvedic traditional healing and treatments.</p>
                    <Link to="/about" style={{padding:"10px",backgroundColor:"#f4365f",color:"white",textDecoration:"none",borderRadius:"5px"}} href="about.html" class="read-more">read more</Link>
                    {/* <div class="signature">
                        <img src="./images/png/signature.png" alt="signature"/>
                        <h4>Richard Jefferson<br/><span>Chief Medical Center</span></h4>
                    </div> */}
                </div>

                <div class="video">
                    {/* <a style={{textDecoration:"none"}} class="btn-video-outer" data-fancybox href="">
                        <span class="btn-video">
                            <i class="fa fa-play btn-play"></i>watch video
                        </span>
                    </a> */}
                    <div class="img">
                        <img className="w-100" src="photo/aacf.jpg"/>
                    </div>
                </div>
            </div>
        </section>
    </>)
}
export default memo(Hospitalinfo)