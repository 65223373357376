import { memo } from "react"
import Servicelist from "../Servicelist"

function MIPHser(){
    return (<>
        <section class="blog blog-single pb-80 pt-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="post-item mb-0">
                  <div class="post__img">
                    <a href="#">
                      <img className="w-100" src="../photo/p15.jpg" alt="post image" loading="lazy"/>
                    </a>
                  </div>
                  <div  class="post__body pb-0">
                    <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                      <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                    </div>
                    <h1 class="post__title mb-30">
                    MIPH(Minimally Invasive Surgery for Hemorrhoids)
                    </h1>
                    <div class="post__desc">
                      <p>MIPH, also known as Minimally Invasive Procedure for Hemorrhoids or Stapled Hemorrhoidopexy, is a surgical technique used to treat hemorrhoids, which are swollen and inflamed veins in the rectum or anus. The procedure is designed to reduce the prolapse (protrusion) of hemorrhoidal tissue and alleviate the associated symptoms such as bleeding, pain, and discomfort.</p>
                      <p>During the MIPH procedure, the patient is placed under general or spinal anesthesia. The surgeon uses a specialized circular stapler to reposition the hemorrhoidal tissue and create a new internal "cuff" or "shelf" to support the anal canal and prevent further prolapse. The staples also cut off the blood supply to the hemorrhoids, causing them to shrink over time.</p>
                      <p>The key advantage of MIPH over traditional hemorrhoidectomy (surgical removal of hemorrhoids) is that it is a minimally invasive technique. The procedure involves small incisions in the anal canal, leading to reduced postoperative pain and a quicker recovery compared to open surgery.

Patients typically experience less discomfort and can resume normal activities more rapidly after MIPH. However, it's essential to note that like any surgical procedure, MIPH carries some risks and potential complications, such as postoperative bleeding, infection, or recurrence of hemorrhoids.</p>
<p>MIPH is generally recommended for patients with grade II or III internal hemorrhoids, where the hemorrhoids prolapse during bowel movements but can be manually repositioned. It may not be suitable for more severe cases or certain medical conditions.</p>
<p>As with any medical procedure, the decision to undergo MIPH should be made after a thorough evaluation by a healthcare professional. The surgeon will assess the patient's specific condition and medical history to determine the most appropriate treatment approach for their individual needs.</p>
                    </div>
                  </div>
                </div>
              </div>
              
                    <Servicelist/>
            </div>
          </div>
        </section>
    </>)
}
export default memo(MIPHser)