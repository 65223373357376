import { memo } from "react"
import Servicelist from "../Servicelist"

function Perianalabscessser(){
    return(<>
        <section class="blog blog-single pb-80 pt-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="post-item mb-0">
                  <div class="post__img">
                    <a href="#">
                      <img className="w-100" src="../photo/p5.jpg" alt="post image" loading="lazy"/>
                    </a>
                  </div>
                  <div  class="post__body pb-0">
                    <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                      <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                    </div>
                    <h1 class="post__title mb-30">
                    Perianal Abscess
                    </h1>
                    <div class="post__desc">
                      <p>A perianal abscess is a painful and localized collection of pus that forms in the tissues around the anus. It typically occurs as a result of a bacterial infection in the anal glands or crypts, which are small openings located just inside the anus. When these glands become blocked or infected, the bacteria can multiply, leading to the formation of an abscess. Perianal abscesses can affect people of all ages but are more common in adults.</p>
                      <p>The symptoms of a perianal abscess include redness, swelling, and tenderness around the anus. The affected area may feel warm to the touch, and patients often experience severe pain, especially during bowel movements. As the abscess grows, it can lead to a visible lump or swelling near the anus, and there may be drainage of pus or fluid from the site of infection.</p>
                      <p>The treatment for a perianal abscess usually involves drainage of the pus to relieve pain and promote healing. Depending on the size and severity of the abscess, drainage may be performed in a doctor's office using local anesthesia or in an operating room under general anesthesia. In some cases, antibiotics may be prescribed to help clear the infection. After drainage, patients are typically advised to keep the area clean and practice good anal hygiene to prevent further infection.</p>
                      <p>If left untreated, perianal abscesses can lead to complications such as the formation of a fistula-in-ano, where the infection creates an abnormal tunnel between the anal canal and the skin. To prevent such complications, it is crucial to seek medical attention promptly if symptoms of a perianal abscess are present. A healthcare professional can accurately diagnose the condition and recommend appropriate treatment to alleviate pain and prevent further complications.</p>
                    </div>
                  </div>
                </div>
              </div>
              
                    <Servicelist/>
            </div>
          </div>
        </section>
    </>)
}
export default memo(Perianalabscessser)