import { memo, useEffect } from "react"
import Contactbanner from "./Contactbanner"
import Newheader from "../Newheader/Newheader"
import Footer from "../Footer/footer"
import Contactform from "./Contactform"
import Map from "./Map"
import { autotoper } from "../Mainfunction"
import Boxaddress from "../Boxinaddress/Boxaddress"

function Conatct(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <Contactbanner/>
    <div>
    <Boxaddress/>
    </div>
    <div style={{marginTop:"0px"}}>
    <Contactform/>
    </div>
    <Footer/>
    </>)
}
export default memo(Conatct)