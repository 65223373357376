import React, { useEffect, useRef, useState } from 'react'

function Gallerybox() {
    
  return (
    <section>
        <div className='container'>
            <div className='rowesy gridss'>
                <div className='talkaer'>
                    <img src="../photo/m1.jpg" alt="" />
                    <img src="../photo/m4.jpg" alt="" />
                    <img src="../photo/m7.jpg" alt="" />
                    <img src="../photo/m10.jpg" alt="" />
                    <img src="../photo/m13.jpg" alt="" />
                    <img src="../photo/m16.jpg" alt="" />
                    <img src="../photo/m19.jpg" alt="" />
                    <img src="../photo/m22.jpg" alt="" />
                    <img src="../photo/m25.jpg" alt="" />
                    <img src="../photo/m28.jpg" alt="" />
                    <img src="../photo/m24.jpg" alt="" />
                </div>
                <div className='talkaer'>
                    <img src="../photo/m2.jpg" alt="" />
                    <img src="../photo/m5.jpg" alt="" />
                    <img src="../photo/m8.jpg" alt="" />
                    <img src="../photo/m11.jpg" alt="" />
                    <img src="../photo/m14.jpg" alt="" />
                    <img src="../photo/m17.jpg" alt="" />
                    <img src="../photo/m20.jpg" alt="" />
                    <img src="../photo/m23.jpg" alt="" />
                    <img src="../photo/m26.jpg" alt="" />
                    <img src="../photo/m27.jpg" alt="" />
                </div>
                <div className='talkaer'>
                    <img src="../photo/m3.jpg" alt="" />
                    <img src="../photo/m6.jpg" alt="" />
                    <img src="../photo/m9.jpg" alt="" />
                    <img src="../photo/m12.jpg" alt="" />
                    <img src="../photo/m15.jpg" alt="" />
                    <img src="../photo/m18.jpg" alt="" />
                    <img src="../photo/m21.jpg" alt="" />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Gallerybox
