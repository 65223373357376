import { memo, useEffect, useState } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./Fixservice.css"
import { Link } from "react-router-dom"
import LazyLoad from "react-lazy-load"
import servicelist from "../Servicelist/Servicelist"
import { LazyLoadImage } from "react-lazy-load-image-component"

function Fixservice(){
return (<>
{/* <section class="services-layout1 services-carousel backimger">
<div class="container">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-6 offset-lg-3 marginerser">
      <div class="heading text-center mb-60">
        <h2 style={{color:"#7b0021"}} class="heading__subtitle">The Best Piles Hospital In Bangalore</h2>
        <h4 style={{lineHeight:"32px"}} class=""><strong>We give comprehensive orthopaedic treatments using the most advanced
technologies at a competitive price with the highest level of care.</strong></h4>
      </div>
    </div>
  </div>
  <div class="">
    <div class="row">
      <div class="slick-carousel rowhomer"
        data-slick='{"slidesToShow": 3, "slidesToScroll": 1, "autoplay": true, "arrows": false, "dots": true, "responsive": [ {"breakpoint": 992, "settings": {"slidesToShow": 2}}, {"breakpoint": 767, "settings": {"slidesToShow": 1}}, {"breakpoint": 480, "settings": {"slidesToShow": 1}}]}'>
        {
          servicelist.map((elem,num)=>{
            const {img, name, contentone}=elem
            {
              return (
                <div class="service-item col-lg-4">
          <div style={{position:"relative"}} class="service__icon">
            <LazyLoadImage style={{width:"100%"}} className="w-100" src={img} alt="" />
          </div>
          <div class="service__content">
            <h4 class="service__title">{name}</h4>
            <p class="service__desc textheigt">{contentone}.
            </p>
            <a href="services-single.html" class="btn btn__secondary btn__outlined btn__rounded">
              <span>Read More</span>
              <i class="icon-arrow-right"></i>
            </a>
          </div>
        </div>
              )
            }
          })
        }
        

      </div>
    </div>
  </div>
</div>
</section> */}
<section style={{paddingTop:"50px"}} class="our-departments back#F4DB7D" id="departments">
            <div class="our-departments__bg">
                <div class="icon">
                    <img src="./images/png-shapes/ornament__right-top.png"/>
                </div>
                <div class="icon">
                    <img src="./images/png-shapes/ornament__left-bottom.png"/>
                </div>
            </div>
            <div class="container">
                <h1 class="section-title section-title_60">our <span>departments</span></h1>
                <div class="our-departments__items">
                    {
                      servicelist.map((elem)=>{
                        const {name,img,to}=elem
                        {
                          return (
                            <Link to={to} style={{border:"1px solid #f22283",textDecoration:"none"}} class="our-departments__inner-item">
                        <div class="icon">
                            <img src={img}/>
                        </div>
                        <h1 style={{color:"3B3B3B"}} class="title">{name}</h1>
                        <p class="text"></p>
                    </Link>
                          )
                        }
                      })
                    }

                </div>
            </div>
        </section>
    </>)
}
export default memo(Fixservice)