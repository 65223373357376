import { memo, useEffect } from "react"
import Aboutbanner from "./Aboutbanner"
import Newheader from "../Newheader/Newheader"
import Footer from "../Footer/footer"
import Hospitalinfo from "../Hospitalinfo/Hospitalinfo"
import Abouthospital from "./Abouthospital"
import Aboutinfo from "./Aboutinfo"
import Galleryhome from "../Galleryhome/Galleryhome"
import { autotoper } from "../Mainfunction"

function About(){
    useEffect(()=>{
        autotoper()
    },[])
    return (<>
    <Newheader/>
    <Aboutbanner/>
    <Abouthospital/>
    <Aboutinfo/>
    <Galleryhome/>
    <Footer/>
    </>)
}
export default memo(About)