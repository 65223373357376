import { memo } from "react"
import Servicelist from "../Servicelist"

function Analfissuresser(){
    return(<>
        <section class="blog blog-single pb-80 pt-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="post-item mb-0">
                  <div class="post__img">
                    <a href="#">
                      <img className="w-100" src="../photo/p3.jpg" alt="post image" loading="lazy"/>
                    </a>
                  </div>
                  <div  class="post__body pb-0">
                    <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                      <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                    </div>
                    <h1 class="post__title mb-30">
                    Anal Fissures
                    </h1>
                    <div class="post__desc">
                      <p>Anal fissures are painful tears or small cracks in the lining of the anus, the lower part of the digestive tract. These fissures can occur due to various factors, including the passage of hard or large stools, chronic constipation, diarrhea, anal intercourse, or childbirth. When passing stools, the sensitive anal tissue stretches and tears, causing discomfort and often leading to bright red blood in the stool or on the toilet paper. The condition can also cause itching, burning, and a feeling of discomfort in the anal region. While anal fissures can affect people of all ages, they are more common in young adults and infants.</p>
                      <p>Thankfully, most anal fissures heal on their own with conservative measures such as dietary changes to soften the stool, topical ointments to reduce inflammation, and warm sitz baths to soothe the area. However, for persistent or severe fissures, medical interventions such as botulinum toxin injections or surgery may be necessary to promote healing and prevent recurrence. Seeking medical advice is essential for an accurate diagnosis and appropriate treatment plan to manage anal fissures effectively. It is crucial to address the underlying causes, such as constipation or diarrhea, to prevent further episodes of anal fissures and to adopt a healthy lifestyle with an emphasis on a fiber-rich diet, regular exercise, and proper hydration. Early detection and timely management can significantly improve the discomfort associated with anal fissures and enhance the overall quality of life for those affected.</p>
                    </div>
                  </div>
                </div>
              </div>
              
                    <Servicelist/>
            </div>
          </div>
        </section>
    </>)
}
export default memo(Analfissuresser)