import { memo, useRef, useState } from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./contactinfo.css"
import { useEffect } from "react"

function ContactBox(){
  
    return (<>
    <section style={{backgroundColor:"#e73665"}} class="contact-layout3 bg-overlay bg-overlay-primary-gradient pb-60">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-7">
            <div class="contact-panel mb-50">
              <form class="contact-panel__form" method="post" action="assets/php/contact.php" id="contactForm">
                <div class="row">
                  <div class="col-sm-12">
                    <h4 class="contact-panel__title">Book An Appointment</h4>
                    <p class="contact-panel__desc mb-30">Please feel free to contact our friendly reception staff with any general or medical enquiry.
                    </p>
                  </div> 
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <i class="icon-news form-group-icon"></i>
                      <input type="text" class="form-control" placeholder="First Name" id="contact-name" name="contact-name"
                        required/>
                    </div>
                  </div> 
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <i class="icon-news form-group-icon"></i>
                      <input type="email" class="form-control" placeholder="Second Name" id="contact-email"
                        name="contact-email" required/>
                    </div>
                  </div> 
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <i class="icon-phone form-group-icon"></i>
                      <input type="text" class="form-control" placeholder="Name" id="contact-name" name="contact-name"
                        required/>
                    </div>
                  </div> 
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <i class="icon-email form-group-icon"></i>
                      <input type="email" class="form-control" placeholder="Email" id="contact-email"
                        name="contact-email" required/>
                    </div>
                  </div> 
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="form-group">
                      <i class="icon-email form-group-icon"></i>
                      <textarea type="email" class="form-control" placeholder="Email" id="contact-email"
                        name="contact-email" required/>
                    </div>
                  </div> 
                  
                  <div class="col-12">
                    <button style={{height:"60px"}} type="submit" class=" btn__rounded btn__block  mt-10 chngershdgc">
                      <span>Book Appointment</span> <i class="icon-arrow-right"></i>
                    </button>
                    <div class="contact-result"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-5">
            <div class="heading heading-light mb-30">
              <h3 class="heading__title mb-30"><strong>ZAIN PILES HOSPITAL</strong></h3>
              <p class="heading__desc">Zain piles hospital is an advanced proctology centre with all types and systems of treatment for piles ,fissure,fistula in ano , ano rectal abscess,rectal prolapse,constipation,anal warts,pilonidal cyst,pilonidal abscess,pilonidal sinus and other conditions related to the ano rectal region.
              </p>
            </div>
            <div class="d-flex align-items-center">
              <a style={{backgroundColor:"yellow",color:"red"}} href="contact-us.html" class="btn btn__white btn__rounded mr-30 btn__primarya">
                <i class="fas fa-phone"></i> <span>Contact Us</span>
              </a>
              <a class="video__btn video__btn-white popup-video removete" href="photo/large-intestine-and-digestive-system-2022-07-21-07-22-26-utc.m">
                <div class="video__player">
                  <i class="fa fa-play"></i>
                </div>
                <span class="video__btn-title color-white">Play Video</span>
              </a>
            </div>
            <div class="text__block"><div class="sinature color-white mb-2">
                <span class="font-weight-bold">Contact</span><span> Address</span>
              </div>
              <p class="text__block-desc color-white font-weight-bold">ZAIN PILES HOSPITAL ,Near to Zamindar Eye Hospital ,10 Th Main ,100 Ft Road ,HRBR LAYOUT,1st Block, Banaswadi, Bangalore, Karnataka 560043</p>
              
            </div>
            <div className="rowhomer">
            <div style={{display:"flex"}} class="slick-carousel clients-light mt-20"
              data-slick='{"slidesToShow": 3, "arrows": false, "dots": false, "autoplay": true,"autoplaySpeed": 2000, "infinite": true, "responsive": [ {"breakpoint": 992, "settings": {"slidesToShow": 3}}, {"breakpoint": 767, "settings": {"slidesToShow": 2}}, {"breakpoint": 480, "settings": {"slidesToShow": 2}}]}'>
              <div class="client col-sm-4 col-4">
                <img src="assets/images/clients/1.png" alt="client"/>
                <img src="assets/images/clients/1.png" alt="client"/>
              </div>  
              <div class="client col-sm-4 col-4">
                <img src="assets/images/clients/2.png" alt="client"/>
                <img src="assets/images/clients/2.png" alt="client"/>
              </div>  
              <div class="client col-sm-4 col-4">
                <img src="assets/images/clients/3.png" alt="client"/>
                <img src="assets/images/clients/3.png" alt="client"/>
              </div>  
              <div class="client col-sm-4 col-4">
                <img src="assets/images/clients/4.png" alt="client"/>
                <img src="assets/images/clients/4.png" alt="client"/>
              </div>  
              <div class="client col-sm-4 col-4">
                <img src="assets/images/clients/5.png" alt="client"/>
                <img src="assets/images/clients/5.png" alt="client"/>
              </div>  
              <div class="client col-sm-4 col-4">
                <img src="assets/images/clients/6.png" alt="client"/>
                <img src="assets/images/clients/6.png" alt="client"/>
              </div>  
              <div class="client col-sm-4 col-4">
                <img src="assets/images/clients/7.png" alt="client"/>
                <img src="assets/images/clients/7.png" alt="client"/>
              </div>  
            </div>
            </div>
          </div>
        </div>
      </div>
      {/* {review} */}
    </section>
    </>)
}
export default memo(ContactBox)