import React, { useEffect } from "react";
import Singledoctorbanner from "./Singledoctorbanner";
import Newheader from "../Newheader/Newheader";
import Footer from "../Footer/footer";
import Boxaddress from "../Boxinaddress/Boxaddress";
import Singledoc from "./Singledoc";
import { autotoper } from "../Mainfunction";

function Singledoctor() {
  useEffect(() => {
    autotoper();
  }, []);
  return (
    <>
      <Newheader />
      <Singledoctorbanner />
      <Boxaddress />
      <Singledoc />
      <Footer />
    </>
  );
}

export default Singledoctor;
