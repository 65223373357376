import { memo } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./Boxaddress.css";
import { Link } from "react-router-dom";

function Boxaddress() {
  return (
    <>
      <section
        style={{ height: "auto", paddingBottom: "0px" }}
        class="contact-info"
      >
        <div class="container">
          <div class="row row-no-gutter boxes-wrapper">
            <div class="col-sm-12 col-lg-4">
              <div
                style={{ backgroundColor: "#1f3d9d" }}
                class="contact-box d-flex"
              >
                <div class="contact__icon">
                  <i class="icon-call3"></i>
                </div>
                <div class="contact__content">
                  <h2 class="contact__title">Emergency Cases</h2>
                  <p class="contact__desc">
                    Please feel free to contact our friendly reception staff
                    with any general or medical enquiry.
                  </p>
                  <a href="tel:+201061245741" class="phone__number removete">
                    <i
                      style={{ backgroundColor: "#7b0021" }}
                      class="icon-phone"
                    ></i>{" "}
                    <span>8867724886</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4">
              <div
                style={{ backgroundColor: "#f22283" }}
                class="contact-box d-flex"
              >
                <div class="contact__icon">
                  <i class="icon-health-report"></i>
                </div>
                <div class="contact__content">
                  <h2 class="contact__title">services</h2>
                  <p class="contact__desc">
                    Zain piles hospital is an advanced proctology centre with
                    all types and systems of treatment.
                  </p>
                  <Link
                    to="/pilestreatments"
                    class="btn btn__white btn__outlined btn__rounded"
                  >
                    <span>Services</span>
                    <i class="icon-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4">
              <div
                style={{ backgroundColor: "#7b0021" }}
                class="contact-box d-flex"
              >
                <div class="contact__icon">
                  <i class="icon-heart2"></i>
                </div>
                <div class="contact__content">
                  <h2 class="contact__title">Opening Hours</h2>
                  <ul
                    style={{ width: "100%" }}
                    class="time__list list-unstyled mb-0"
                  >
                    <li
                      className="w-100"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Mon - Sun</span>
                      <span> 24x7</span>
                    </li>

                    <p class="contact__desc">
                      Our staff strives to make each interaction with patients
                      clear, concise, and inviting.
                    </p>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4 snjkascnmc">
              <div
                style={{ backgroundColor: "#1f3d9d" }}
                class="contact-box d-flex"
              >
                {/* <div class="contact__icon">
                <img className="imagersma" style={{width:"50px"}} src="../photo/map.png" alt="" />
              </div> */}
                <div class="w-100">
                  <h2 class="contact__title">Contact Address</h2>

                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.2773317364836!2d77.6489933793457!3d13.018003150023782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae112072f4fc65%3A0xe28b0fe3eeb10a29!2sZAIN%20PILES%20HOSPITAL!5e0!3m2!1sen!2sin!4v1690539079949!5m2!1sen!2sin"
                    style={{ width: "100%", height: "150px" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                  {/* <a href="tel:+201061245741" class="phone__number removete">
                  <i style={{backgroundColor:"#7b0021"}} class="icon-email"></i> <span>8867724886</span>
                </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default memo(Boxaddress);
