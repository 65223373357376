import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import FistulaInAnobanner from "./Fistula-in-anobanner"
import FistulaInAnoser from "./Fistula-in-anoser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function Fistulainano(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <FistulaInAnobanner/>
    <FistulaInAnoser/>
    <Footer/>
    </>)
}
export default memo(Fistulainano)