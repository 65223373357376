import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Chroniculcerbanner from "./Chroniculcerbanner"
import Chroniculcerser from "./Chroniculcerser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function Chroniculcer(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <Chroniculcerbanner/>
    <Chroniculcerser/>
    <Footer/>
    </>)
}
export default memo(Chroniculcer)