import React, { useEffect } from 'react'
import Newheader from '../Newheader/Newheader'
import Footer from '../Footer/footer'
import Gallerybanner from './Gallerybanner'
import Gallerybox from './Gallerybox'
import { autotoper } from '../Mainfunction'

const Gallery = () => {
  useEffect(()=>{
    autotoper()
},[])
  return (<>
  <Newheader/>
  <Gallerybanner/>
  <Gallerybox/>
  <Footer/>
  </>)
}

export default Gallery
