import React from "react";
import { Link } from "react-router-dom";

function Doctortwo() {
  return (
    <>
      <section
        style={{ padding: "50px 0px", position: "relative" }}
        class="form aboibdsj"
      >
        <div style={{ padding: "50px 0px" }} class="container">
          <div className="row">
            <div class="col-lg-3 col-12">
              <img style={{ marginBottom: "30px" }} src="photo/sjbhdv.jpg" />
            </div>
            <div
              style={{ display: "flex", alignItems: "center", padding: "20px" }}
              class="col-lg-9 col-12"
            >
              <div>
                <h1 class="section-title">
                  <span>Dr. MUJAHID PASHA M</span>
                </h1>
                <div>
                  <h5 style={{ color: "#f22283" }}>
                    Piles Specialist , Family Physician , Proctologist <br />{" "}
                    Cosmetologist & Ayurvedic Consultant{" "}
                  </h5>
                </div>
                <div>
                  <p style={{ color: "#7b0021" }}>
                    MBBS, BAMS, PGDCC, FAAM, CCKT, Fellowship in
                    Gastroenterology.
                  </p>
                </div>
                <p style={{ textAlign: "justify" }} class="subtitle">
                  Dr. Mujahid Pasha M is one of the few doctors in India with
                  MBBS & BAMS, PGDCC, FAAM, CCKT, Fellowship in Gastroenterology
                  and can treat piles and other patients Allopathic medicines ,
                  surgery or ayurvedic traditional healing and treatments.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Dr MUJAHID PASHA,who is well versed in Ayurvedic as well as
                  Allopathic medical science due to his dual degrees BAMS,MBBS .
                  Also has done his additional course for fistula treatment that
                  is KSHARA SUTRA THERAPY .
                </p>
                <Link
                  to="/mujahidpasha"
                  style={{
                    backgroundColor: "#f22283",
                    padding: "10px 20px",
                    textDecoration: "none",
                    color: "white",
                    borderRadius: "20px",
                    marginRight: "8px",
                  }}
                  className="appointment"
                >
                  Read More
                </Link>
                <a
                  href="https://click4appointment.com/book-appointment-guest/502168/3326"
                  style={{
                    backgroundColor: "#f22283",
                    padding: "10px 20px",
                    textDecoration: "none",
                    color: "white",
                    borderRadius: "20px",
                  }}
                  className="appointment"
                >
                  Book an appointment
                </a>
              </div>
            </div>
          </div>
        </div>

        <div style={{ padding: "30px 0px" }} class="container">
          <div className="row">
            <div class="col-lg-3 col-12">
              <img
                style={{ marginBottom: "30px" }}
                src="photo/doctor (2).png"
              />
            </div>
            <div
              style={{ display: "flex", alignItems: "center", padding: "20px" }}
              class="col-lg-9 col-12"
            >
              <div>
                <h1 class="section-title">
                  <span>Dr. HEENA MUJAHID</span>
                </h1>
                <div>
                  <h5 style={{ color: "#f22283" }}>
                    Family Physician , Proctologist
                    <br />& Infectious Diseases Specialist
                  </h5>
                </div>
                <div>
                  <p style={{ color: "#7b0021" }}>MBBS, CCID, CCKT</p>
                </div>
                <p style={{ textAlign: "justify" }} class="subtitle"></p>
                <p style={{ textAlign: "justify" }}>
                  Dr. Heena Mujahid is a versatile medical expert, skilled in
                  family medicine, proctology, and infectious diseases. With her
                  MBBS qualification and additional certifications, including
                  CCID and CCKT, she offers comprehensive care, blending her
                  knowledge to ensure holistic well-being for her patients. Her
                  dedication and expertise make her a trusted advocate for
                  health and healing.
                </p>

                <a
                  href="https://click4appointment.com/book-appointment-guest/502168/3326"
                  style={{
                    backgroundColor: "#f22283",
                    padding: "10px 20px",
                    textDecoration: "none",
                    color: "white",
                    borderRadius: "20px",
                  }}
                  className="appointment"
                >
                  Book an appointment
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Doctortwo;
