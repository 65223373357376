import { memo } from "react"

function Video(){
    return (<>
    <section className="sgcbjsdhfcdnz">
        <div className="container">
            <div className="row mb-4">
          
          <h1 class="section-title section-title_60">patients <span style={{color:"white"}}>Educations</span></h1>
            </div>
            <div style={{width:"100%"}} className="row">
                <div className="col-xl-4 mb-2">
                <iframe width="100%" className="hiknjkbhm"
src="https://www.youtube.com/embed/VfHNUAHU5O8">
</iframe>
                </div>
                <div className="col-xl-4 mb-2">
                <iframe width="100%" className="hiknjkbhm"
src="https://www.youtube.com/embed/gVVPfz8JaMI">
</iframe>


                
                </div>
                <div className="col-xl-4 mb-2">
                <iframe width="100%" className="hiknjkbhm"
src="https://www.youtube.com/embed/msyHv3So-9s">
</iframe>
                
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Video)