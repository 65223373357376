import { memo, useEffect } from "react"
import Servicebox from "../ServiceboxHome/Servicebox"
import Footer from "../Footer/footer"
import Contactinfo from "../Contactinfo/contactinfo"
import Testimonialhome from "../Testimonialinfo/Testimonialhome"
import Servicesinhome from "../Servicesinhome/Servicesinhome"
import Hospitalinfo from "../Hospitalinfo/Hospitalinfo"
import Boxaddress from "../Boxinaddress/Boxaddress"
import Backfirstfix from "../Backfirstfix/Backfirstfix"
import Galleryhome from "../Galleryhome/Galleryhome"
import Fixservice from "../Fixservice/Fixservice"
import Review from "../Review/Review"
import Slider from "../Slider/Slider"
import Task from "../Taskone.jsx/Task"
import Newslider from "../Newheader/Newslider"
import Emergency from "../Emergency/Emergency"
import OurDoctor from "../OureDoctor/OurDoctor"
import Videos from "../Videos/videos"
import Newheader from "../Newheader/Newheader"
import Appointment from "../Appoitmenter/Appointment"
import { autotoper } from "../Mainfunction"

function Home(){
    useEffect(()=>{
        autotoper()
    },[])
    return (<>
    <Newheader/>
    <Slider/>
    {/* <Newslider/> */}
    <Boxaddress/>
    <Hospitalinfo/>
    <Emergency/>
    {/* <Contactinfo/> */}
    <Fixservice/>
    <Testimonialhome/>
    <OurDoctor/>
    <Videos/>
    {/* <Contactinfo/> */}
    {/* <Testimonialhome/> */}
    <Galleryhome/>
    <Footer/>
    </>)
}
export default memo(Home)