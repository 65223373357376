import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import MIPHbanner from "./MIPHbanner"
import MIPHser from "./MIPHser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function MIPH(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <MIPHbanner/>
    <MIPHser/>
    <Footer/>
    </>)
}
export default memo(MIPH)