import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Fistulatreatmentbanner from "./Fistulatreatmentbanner"
import Fistulatreatmentser from "./Fistulatreatmentser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function Fistulatreatment(){
    useEffect(()=>{
        autotoper()
    },[])
    return (<>
    <Newheader/>
    <Fistulatreatmentbanner/>
    <Fistulatreatmentser/>
    <Footer/>
    </>)
}
export default memo(Fistulatreatment)