import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Analfissuresbanner from "./Analfissuresbanner"
import Analfissuresser from "./Analfissuresser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function Analfissures(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <Analfissuresbanner/>
    <Analfissuresser/>
    <Footer/>
    </>)
}
export default memo(Analfissures)