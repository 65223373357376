import { memo } from "react"
import Servicelist from "../Servicelist"

function Hemorrhoidstreatmentser(){
    return (<>
        <section class="blog blog-single pb-80 pt-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="post-item mb-0">
                  <div class="post__img">
                    <a href="#">
                      <img className="w-100" src="../photo/p13.jpg" alt="post image" loading="lazy"/>
                    </a>
                  </div>
                  <div  class="post__body pb-0">
                    <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                      <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                    </div>
                    <h1 class="post__title mb-30">
                    Hemorrhoids Treatment
                    </h1>
                    <div class="post__desc">
                      <p>Hemorrhoids are swollen and inflamed veins in the rectum or anus, which can cause discomfort, itching, pain, and bleeding. Treatment for hemorrhoids depends on the severity of symptoms and the type of hemorrhoids present.</p>
                      <p>Mild cases of hemorrhoids can often be managed at home through lifestyle changes and self-care measures. These may include increasing dietary fiber intake to soften stools, staying hydrated, avoiding prolonged sitting or straining during bowel movements, and practicing good anal hygiene. Over-the-counter creams or ointments containing hydrocortisone or witch hazel may provide temporary relief from itching and discomfort.</p>
                      <p>For more severe or persistent hemorrhoids, medical intervention may be necessary. Non-surgical treatments like rubber band ligation involve placing a small rubber band around the base of the hemorrhoid to cut off its blood supply, causing it to shrink and fall off within a few days.</p>
                      <p>Sclerotherapy is another non-surgical option, where a chemical solution is injected into the hemorrhoid to shrink it. Infrared coagulation uses heat to coagulate the hemorrhoid tissue, causing it to scar and shrink.

In some cases, especially when hemorrhoids are large or causing significant symptoms, surgical procedures may be recommended. Hemorrhoidectomy involves surgically removing the hemorrhoid tissue, and it is typically used for more severe cases.

Stapled hemorrhoidopexy, or stapling, is a less invasive surgical procedure that involves using a circular stapler to reposition and shrink the hemorrhoid tissue.</p>
<p>The appropriate treatment for hemorrhoids is determined by a healthcare professional after evaluating the individual's specific condition and symptoms. They can provide guidance on the most suitable treatment option, offer symptom relief, and help prevent future hemorrhoid flare-ups.</p>
                    </div>
                  </div>
                </div>
              </div>
              
                    <Servicelist/>
            </div>
          </div>
        </section>
    </>)
}
export default memo(Hemorrhoidstreatmentser)