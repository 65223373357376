import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Laserbanner from "./Laserbanner"
import Laserser from "./Laserser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function Laser(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <Laserbanner/>
    <Laserser/>
    <Footer/>
    </>)
}
export default memo(Laser)