import { memo, useEffect } from "react"
import Newheader from "../Newheader/Newheader"
import Doctorbanner from "./Doctorbanner"
import Footer from "../Footer/footer"
import OurDoctor from "../OureDoctor/OurDoctor"
import Boxaddress from "../Boxinaddress/Boxaddress"
import Doctortwo from "../Doctortwo/Doctortwo"
import { autotoper } from "../Mainfunction"

function Doctor(){
    useEffect(()=>{
        autotoper()
    },[])
    return (<>
    <Newheader/>
    <Doctorbanner/>
    <Boxaddress/>
    <Doctortwo/>
    <Footer/>
    </>)
}
export default memo(Doctor)