import { memo } from "react"
import { Link } from "react-router-dom"

function Servicelist(){
  const menu=[
    {
      name:"Piles Treatments",
      content:"Piles, also known as hemorrhoids, are swollen and inflamed...",
      img:"../photo/x1.jpg",
      to:"/pilestreatments",
    },
    {
      name:"Piles Surgery ",
      content:"Piles surgery, also known as hemorrhoidectomy, is a medical...",
      img:"../photo/p2.jpg",
      to:"/pilessurgery",
    },
    {
      name:"Anal Fissures",
      content:"Anal fissures are painful tears or small cracks in the lining...",
      img:"../photo/p3.jpg",
      to:"/analfissures",
    },
    {
      name:"Anal Polyps",
      content:"Anal polyps are growths that develop in the lining...",
      img:"../photo/p4.jpg",
      to:"/analpolyps",
    },
    {
      name:"Fistula- in- Ano",
      content:"Fistula-in-ano, also referred to as an anal fistula, is a medical...",
      img:"../photo/p7.jpg",
      to:"/fistulaInano",
    },
    {
      name:"Rectal Prolapse",
      content:"Rectal prolapse is a medical condition in which the rectum...",
      img:"../photo/p6.jpg",
      to:"/rectalprolapse",
    },
    {
      name:"Perianal Abscess",
      content:"A perianal abscess is a painful and localized collection of pus...",
      img:"../photo/p5.jpg",
      to:"/perianalabscess",
    },
    {
      name:"Anal Skintags",
      content:"Anal fissures are painful tears or small cracks in the lining...",
      img:"../photo/p8.jpg",
      to:"/analskintags",
    },
    {
      name:"Constipation",
      content:"Constipation is a common digestive issue characterized by infrequent...",
      img:"../photo/p9.jpg",
      to:"/constipation",
    },
    {
      name:"IBS (Irritable Bowel Syndrome)",
      content:"Irritable Bowel Syndrome (IBS) is a common functional...",
      img:"../photo/p10.jpg",
      to:"/ibs",
    },
    {
      name:"Fistula Surgery",
      content:"Fistula surgery is a medical procedure performed to treat...",
      img:"../photo/p11.jpg",
      to:"/fistulasurgery",
    },
    {
      name:"Fistula Treatment",
      content:"The treatment of a fistula depends on its location...",
      img:"../photo/p12.jpg",
      to:"/fistulatreatment",
    },
    {
      name:"Hemorrhoids Treatment",
      content:"Hemorrhoids are swollen and inflamed veins in the ...",
      img:"../photo/p13.jpg",
      to:"/hemorrhoidstreatment",
    },
    {
      name:"Endoscopic Pilonidal Sinus Treatment",
      content:"Endoscopic pilonidal sinus treatment, also known as...",
      img:"../photo/p14.jpg",
      to:"/endoscopic",
    },
    {
      name:"MIPH(Minimally Invasive Surgery for Hemorrhoids)",
      content:"MIPH, also known as Minimally Invasive Procedure for...",
      img:"../photo/p15.jpg",
      to:"/miph",
    },
    {
      name:"Laser Treatment For Piles",
      content:"Laser treatment for piles, also known as laser...",
      img:"../photo/p16.jpg",
      to:"/laser",
    },
    {
      name:"Anal Warts Treatments ",
      content:"Anal warts, also known as condyloma acuminata, are...",
      img:"../photo/p17.jpg",
      to:"/anal",
    },
    {
      name:"Chronic Ulcer",
      content:"A chronic ulcer is a type of wound that fails to heal...",
      img:"../photo/p18.jpg",
      to:"/chroniculcer",
    },
    {
      name:"Infertility",
      content:"Infertility due to piles (hemorrhoids) is a rare occurrence...",
      img:"../photo/p19.jpg",
      to:"/infertility",
    },
    {
      name:"Circumcision (Khatna)",
      content:"Circumcision is a surgical procedure that involves the...",
      img:"../photo/p20.jpg",
      to:"/circumcision",
    },
    {
      name:"Sex Problems & Treatments",
      content:"Piles, also known as hemorrhoids, are swollen and inflamed...",
      img:"../photo/p21.jpg",
      to:"/sexproblems",
    },
  ]
    return (<>
    
    <div class="col-sm-12 col-md-12 col-lg-4">
                <aside class="sidebar">
                  <div style={{backgroundColor:"#f4365f"}} class="widget widget-posts">
                    <h5 style={{color:"white"}} class="widget__title">Departments</h5>
    <div class="widget__content widthedoc">
    
    {
      menu.map((elem)=>{
        const {name,content,img,to}=elem
        {
          return (<>
          <Link to={to} style={{backgroundColor:"#7b0021",padding:"24px",borderRadius:"8px",textDecoration:"none"}} class="widget-post-item d-flex align-items-center p-2 alianer">
      <div class="widget-post__img">
        <a href="#"><img src={img} alt="thumb"/></a>
      </div>
      <div class="widget-post__content">
        <span style={{color:"white"}} class="widget-post__date">{name}</span>
        <h4 class="widget-post__title"><a style={{textDecoration:"none",color:"white"}} href="#">{content}</a>
        </h4>
      </div>
    </Link>
          </>)
        }
      })
    }


  </div>
  
  </div>
            </aside>
          </div>
    </>)
}
export default memo(Servicelist)