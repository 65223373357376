const servicelist=[
    {
        img:"photo/Piles.png",
        name:"Piles Treatments",
        contentone:"Piles, also known as hemorrhoids, are swollen blood vessels in the rectal area that can cause discomfort and pain.",
        link:"",
        to:"/pilestreatments",
    },
    {
        img:"photo/pilessurgery.png",
        name:"Piles Surgery",
        contentone:"Piles surgery, also known as hemorrhoidectomy, is a surgical procedure performed to remove severe or recurring hemorrhoids that have not responded to non-surgical treatments.",
        link:"",
        to:"/pilessurgery",
    },
    {
        img:"photo/analFissures.png",
        name:"Anal Fissures",
        contentone:"Anal fissures are small tears or cuts in the lining of the anal canal, typically caused by passing hard or large stools.",
        link:"",
        to:"/analfissures",
    },
    {
        img:"photo/analpolyps.png",
        name:"Anal Polyps",
        contentone:"Anal polyps are abnormal growths that develop in the lining of the anus or rectum. They are typically benign (non-cancerous) and can vary in size and shape.",
        link:"",
        to:"/analpolyps",
    },
    {
        img:"photo/ff1.png",
        name:"Fistula- in- Ano",
        contentone:"A fistula-in-ano is an abnormal tunnel that forms between the anal canal or rectum and the skin surrounding the anus. It is typically the result of an infection or an abscess in the anal glands.",
        link:"",
        to:"/fistulaInano",
    },
    {
        img:"photo/rectalprolapse.png",
        name:"Rectal Prolapse",
        contentone:"Rectal prolapse occurs when the rectum, the lower part of the large intestine, protrudes or falls outside of the anus.",
        link:"",
        to:"/rectalprolapse'",
    },
    {
        img:"../photo/perianalabscess.png",
        name:"Perianal Abscess",
        contentone:"A perianal abscess is a painful, localized collection of pus that forms in the tissues surrounding the anus. It is usually caused by an infection in the anal glands or nearby glands.",
        link:"",
        to:"/perianalabscess",
    },
    {
        img:"photo/analskintags.png",
        name:"Anal Skintags",
        contentone:"Anal skin tags, also known as acrochordons or rectal skin tags, are small, benign growths of skin that typically occur in the anal or perianal area.",
        link:"",
        to:"/analskintags",
    },
]
export default servicelist;