import { memo, useEffect } from "react"
import Newheader from "../Newheader/Newheader"
import Footer from "../Footer/footer"
import Blogbanner from "./Blogbanner"
import Galleryhome from "../Galleryhome/Galleryhome"
import { autotoper } from "../Mainfunction"

function Blogpage(){
    useEffect(()=>{
        autotoper()
    },[])
    return (<>
    <Newheader/>
    <Blogbanner/>
    <Galleryhome/>
    <Footer/>
    </>)
}
export default memo(Blogpage)