import { memo } from "react"
import Servicelist from "../Servicelist"

function Endoscopicser(){
    return (<>
        <section class="blog blog-single pb-80 pt-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="post-item mb-0">
                  <div class="post__img">
                    <a href="#">
                      <img className="w-100" src="../photo/p14.jpg" alt="post image" loading="lazy"/>
                    </a>
                  </div>
                  <div  class="post__body pb-0">
                    <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                      <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                    </div>
                    <h1 class="post__title mb-30">
                    Endoscopic Pilonidal Sinus Treatment
                    </h1>
                    <div class="post__desc">
                      <p>Endoscopic pilonidal sinus treatment, also known as endoscopic pilonidal sinus surgery or E.P.S.I.T., is a minimally invasive procedure used to treat pilonidal sinus disease. Pilonidal sinus is a condition where a small tunnel or tract forms under the skin, typically at the top of the cleft of the buttocks, and may become infected, causing pain and discomfort.</p>
                      <p>During the endoscopic pilonidal sinus treatment, the surgeon makes a small incision near the sinus opening and inserts an endoscope, which is a thin, flexible tube with a camera and light at its tip. The endoscope allows the surgeon to visualize the sinus tract and surrounding tissues on a screen. Using specialized instruments, the surgeon removes the sinus tract and any infected or inflamed tissue.</p>
                      <p>One of the key advantages of this procedure is its minimally invasive nature, resulting in smaller incisions and reduced tissue trauma compared to traditional open surgery. This can lead to less postoperative pain, quicker recovery, and potentially better cosmetic outcomes.

Endoscopic pilonidal sinus treatment is typically performed under general anesthesia or local anesthesia with sedation. The procedure is suitable for certain types of pilonidal sinus disease and may not be suitable for more complex cases or those with extensive involvement.</p>
<p>Following the procedure, patients may experience some discomfort and are advised to keep the surgical site clean and dry during the healing process. The recovery period is usually shorter compared to traditional open surgery, allowing patients to return to their daily activities sooner.</p>
<p>As with any medical procedure, endoscopic pilonidal sinus treatment has its own risks and potential complications, and it is essential for patients to discuss the benefits and risks with their surgeon to determine if this approach is appropriate for their specific condition.</p>
                    </div>
                  </div>
                </div>
              </div>
              
                    <Servicelist/>
            </div>
          </div>
        </section>
    </>)
}
export default memo(Endoscopicser)