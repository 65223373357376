import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Hemorrhoidstreatmentbanner from "./Hemorrhoidstreatmentbanner"
import Hemorrhoidstreatmentser from "./Hemorrhoidstreatmentser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function Hemorrhoidstreatment(){
    useEffect(()=>{
        autotoper()
    },[])
    return (<>
    <Newheader/>
    <Hemorrhoidstreatmentbanner/>
    <Hemorrhoidstreatmentser/>
    <Footer/>
    </>)
}
export default memo(Hemorrhoidstreatment)