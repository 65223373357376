import React from "react";
import { Link } from "react-router-dom";

function Singledoctorbanner() {
  return (
    <section
      style={{ backgroundImage: "url(../photo/m8.jpg)" }}
      class="page-title page-title-layout1 bg-overlay piletratmentsbanner"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5">
            <h1 style={{ color: "white" }} class="pagetitle__heading">
              Dr. Mujahid Pasha M
            </h1>
            <p style={{ color: "white" }} class="pagetitle__desc">
              Dr. Mujahid Pasha M is one of the few doctors in India with MBBS &
              BAMS, PGDCC, FAAM, CCKT, Fellowship in Gastroenterology and can
              treat piles and other patients Allopathic medicines , surgery or
              ayurvedic traditional healing and treatments.
            </p>
            <div class="d-flex flex-wrap align-items-center">
              <Link
                to="/"
                style={{ backgroundColor: "#ffff00", color: "black" }}
                class="btn btn__primary btn__rounded mr-30"
              >
                <span>Home</span>
                <i class="icon-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Singledoctorbanner;
