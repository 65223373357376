import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Rectalprolapsebanner from "./Rectalprolapsebanner"
import Rectalprolapseser from "./Rectalprolapseser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function Rectalprolapse(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <Rectalprolapsebanner/>
    <Rectalprolapseser/>
    <Footer/>
    </>)
}
export default memo(Rectalprolapse)