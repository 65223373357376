import { Link } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Carousel from 'react-bootstrap/Carousel';
import { memo, useEffect, useState } from "react";

function Slider() {

  const [index, setIndex] = useState(0);

    let slidermover

  useEffect(()=>{
    slidermover=setInterval(()=>{
      if(index<3){
          setIndex(index+1);
      }else{
          setIndex(0)
      }
  },5000)
  return ()=>{
      clearInterval(slidermover);
  }
 });
 function rightarrow(){
    clearInterval(slidermover)
    if(index<3){
        setIndex(index+1);
    }else{
        setIndex(0)
    }
 }
 function leftarrow(){
    clearInterval(slidermover)
    if(index>0){
        setIndex(index-1);
        console.log(index);
    }else{
        setIndex(3)
        console.log(index);
    }
 }

 const transfer={
  transform:`translateX(-${index*100}%)`
 }
  return (<>
  <div>
      <div className="rowhomehi relaterc">
          <div className="rowhome" style={transfer}>
              <div style={{padding:"0px"}} className="col-12">
                  <img className="biger" src="photo/allb1.jpg" alt="" />
                  <img className="smaller" src="photo/alls1.jpg" alt="" />
              </div>
              <div style={{padding:"0px"}} className="col-12">
                  <img className="biger" src="photo/allb2.jpg" alt="" />
                  <img className="smaller" src="photo/alls2.jpg" alt="" />
              </div>
              <div style={{padding:"0px"}} className="col-12">
                  <img className="biger" src="photo/analb.jpg" alt="" />
                  <img className="smaller" src="photo/anals.jpg" alt="" />
              </div>
              <div style={{padding:"0px"}} className="col-12">
                  <img className="biger" src="photo/hemob.jpg" alt="" />
                  <img className="smaller" src="photo/hemos.jpg" alt="" />
              </div>
          </div>
          <div onClick={rightarrow} className="alianer-imger right-arrow">
            <img src="../photo/right-arrow.png" alt="" />
          </div>
          <div onClick={leftarrow} className="alianer-imger left-arrow">
            <img src="../photo/left-arrow.png" alt="" />
          </div>
      </div>
  </div></>
);
}

export default memo(Slider);