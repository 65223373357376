import { memo } from "react"
import Servicelist from "../Servicelist"

function Analskintagsser(){
    return(<>
        <section class="blog blog-single pb-80 pt-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="post-item mb-0">
                  <div class="post__img">
                    <a href="#">
                      <img className="w-100" src="../photo/p8.jpg" alt="post image" loading="lazy"/>
                    </a>
                  </div>
                  <div  class="post__body pb-0">
                    <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                      <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                    </div>
                    <h1 class="post__title mb-30">
                    Anal Skintags
                    </h1>
                    <div class="post__desc">
                      <p>Anal skin tags, also known as acrochordons or perianal skin tags, are benign, harmless growths of excess skin that appear around the anus. They are small, soft, and typically flesh-colored or slightly darker than the surrounding skin. Anal skin tags are relatively common and can affect people of all ages, including both men and women. While they are usually painless, they may cause discomfort or irritation if they rub against clothing or during bowel movements.</p>
                      <p>The exact cause of anal skin tags is not always clear, but they are often associated with certain factors, such as obesity, pregnancy, chronic constipation, or excessive friction in the anal area. In some cases, previous anal surgery or inflammatory conditions like Crohn's disease can also contribute to their development.</p>
                      <p>It's essential to consult a healthcare provider to determine the appropriate course of action, especially if there is uncertainty about the nature of the growth or if there is any accompanying discomfort, bleeding, or changes in color. While anal skin tags are typically harmless, other conditions, such as anal warts or hemorrhoids, may present with similar symptoms, and proper diagnosis is essential to ensure proper management.</p>
                    </div>
                  </div>
                </div>
              </div>
              
                    <Servicelist/>
            </div>
          </div>
        </section>
    </>)
}
export default memo(Analskintagsser)