import { memo, useEffect } from "react"
import "../Treatment.css"
import Newheader from "../../Newheader/Newheader"
import Pilestreatmentsbanner from "./Pilestreatmentsbanner"
import Footer from "../../Footer/footer"
import Pilestreatmentsser from "./Pilestreatmentsser"
import { autotoper } from "../../Mainfunction"

function Pilestreatments(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <Pilestreatmentsbanner/>
    <Pilestreatmentsser/>
    <Footer/>
    </>)
}
export default memo(Pilestreatments)