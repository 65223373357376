import { memo, useEffect, useRef, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./Testimonialhome.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazy-load";

function Review(){
  //   const review=useRef()
  // useEffect(() =>{
  //   const script=document.createElement('script');
  //   script.src="https://cdn.trustindex.io/loader.js?b5a39c6172920980e2367090f9c"
  //   script.async=true;
  //   review.current.appendChild(script)
  //   return ()=>{
  //     review.current.removeChild(script)
  //   }
  // }, []);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
        return (<>
            <section class="work-process work-process-carousel pt-70 pb-70 bg-overlay bg-overlay-secondary sgcbjsdhfcdnz">
      <div class="container">
        <div className="col-12">
          
        <h1 class="section-title section-title_60">What our <span style={{color:"white"}}>patients says</span></h1>

        </div>
        <div className="container">
        <div style={{marginTop:"20px"}} className="col-12">
        <Carousel
  swipeable={false}
  draggable={false}
  // showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  // autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={1000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  // containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  // deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
  <div>
    <div style={{padding:"3px"}} className="reviewrr">
      <div style={{backgroundColor:"white",borderRadius:"5px",padding:"16px"}}>
        <div className="row">
        <div className="col-3">
          <img style={{borderRadius:"50%"}} src="../photo/r1.jpeg" alt="" />
        </div>
        <div style={{display:'flex',alignItems:'center',padding:"0px"}} className="col-9">
          <div>
          <h5 style={{marginBottom:"0px"}}>Suresh Goondla</h5>
          <p style={{marginBottom:"0px"}}>reviews·34 photos</p>
          </div>
        </div>
        </div>
        <div style={{marginTop:"0px",display:"flex",textAlign:"center",justifyContent:"center",height:"260px",overflow:"hidden",overflowY:"scroll",marginBottom:"20px"}}>
        <div>
        <ul style={{display:"flex",marginLeft:"0px",paddingLeft:"0px",justifyContent:"center"}}>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
          </ul>
          <p style={{lineHeight:"26px"}}>Initially I thought the same.. all 5 starts are paid reviews.. But literally they are genuine reviews.

Hospital ambience is very good. Dr. Muzahid Pasha is very responsive and friendly. He gave pain less laser treatment to my wife. We are happy with the treatment. He is very knowledgeable, we can go with this doctor without second thought. Also he helped claiming our insurance. Thank you :)</p>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div style={{padding:"3px"}} className="reviewrr">
      <div style={{backgroundColor:"white",borderRadius:"5px",padding:"16px"}}>
        <div className="row">
        <div className="col-3">
          <img style={{borderRadius:"50%"}} src="../photo/r2.jpg" alt="" />
        </div>
        <div style={{display:'flex',alignItems:'center',padding:"0px"}} className="col-9">
          <div>
          <h5 style={{marginBottom:"0px"}}>shah fahad</h5>
          <p style={{marginBottom:"0px"}}>4 reviews</p>
          </div>
        </div>
        </div>
        <div style={{marginTop:"0px",display:"flex",textAlign:"center",justifyContent:"center",height:"260px",overflow:"hidden",overflowY:"scroll",marginBottom:"20px"}}>
        <div>
        <ul style={{display:"flex",marginLeft:"0px",paddingLeft:"0px",justifyContent:"center"}}>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
          </ul>
          <p style={{lineHeight:"26px"}}>The best doctor and the very friendly staff, it doesn’t feel like there is a surgery done thanks to dr. Mujahid sir, please do keep the good work going 😊</p>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div style={{padding:"3px"}} className="reviewrr">
      <div style={{backgroundColor:"white",borderRadius:"5px",padding:"16px"}}>
        <div className="row">
        <div className="col-3">
          <img style={{borderRadius:"50%"}} src="../photo/letter-n.png" alt="" />
        </div>
        <div style={{display:'flex',alignItems:'center',padding:"0px"}} className="col-9">
          <div>
          <h5 style={{marginBottom:"0px"}}>Nagu Devi</h5>
          <p style={{marginBottom:"0px"}}>4 reviews</p>
          </div>
        </div>
        </div>
        <div style={{marginTop:"0px",display:"flex",textAlign:"center",justifyContent:"center",height:"260px",overflow:"hidden",overflowY:"scroll",marginBottom:"20px"}}>
        <div>
        <ul style={{display:"flex",marginLeft:"0px",paddingLeft:"0px",justifyContent:"center"}}>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
          </ul>
          <p style={{lineHeight:"26px"}}>Top notch experience, I had visited for hemorrhoid third stage.The surgery was done in no time and painless. I recovered in 3 days, all thanks to DR.Mujahid pasha, more than a doctor he was like a brother.
The warmth and confidence was needed for me because it was the first surgery in my life,and he actually gave all of that and made me comfortable
Thank you doctor</p>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div style={{padding:"3px"}} className="reviewrr">
      <div style={{backgroundColor:"white",borderRadius:"5px",padding:"16px"}}>
        <div className="row">
        <div className="col-3">
          <img style={{borderRadius:"50%"}} src="../photo/letter-w.png" alt="" />
        </div>
        <div style={{display:'flex',alignItems:'center',padding:"0px"}} className="col-9">
          <div>
          <h5 style={{marginBottom:"0px"}}>Winner Viagularaj</h5>
          <p style={{marginBottom:"0px"}}>5 reviews·2 photos
</p>
          </div>
        </div>
        </div>
        <div style={{marginTop:"0px",display:"flex",textAlign:"center",justifyContent:"center",height:"260px",overflow:"hidden",overflowY:"scroll",marginBottom:"20px"}}>
        <div>
        <ul style={{display:"flex",marginLeft:"0px",paddingLeft:"0px",justifyContent:"center"}}>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
          </ul>
          <p style={{lineHeight:"26px"}}>
My experience in getting the Laser procedure and complete cure from external hemorrhoids was unforgettable, although I had lot of suffering but Dr. Mujahid Pasha made it possible to bear the pain with his excellent treatment and he got me back on my job soon. I appreciate his gesture as he delt with me in a kind and courteous manner. I would definitely recommend patients to seek his medication and advice...
God bless you Doctor and your family!</p>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div style={{padding:"3px"}} className="reviewrr">
      <div style={{backgroundColor:"white",borderRadius:"5px",padding:"16px"}}>
        <div className="row">
        <div className="col-3">
          <img style={{borderRadius:"50%"}} src="../photo/s.png" alt="" />
        </div>
        <div style={{display:'flex',alignItems:'center',padding:"0px"}} className="col-9">
          <div>
          <h5 style={{marginBottom:"0px"}}>Sree Harsha Sure</h5>
          <p style={{marginBottom:"0px"}}>27 reviews·1 photo
</p>
          </div>
        </div>
        </div>
        <div style={{marginTop:"0px",display:"flex",textAlign:"center",justifyContent:"center",height:"260px",overflow:"hidden",overflowY:"scroll",marginBottom:"20px"}}>
        <div>
        <ul style={{display:"flex",marginLeft:"0px",paddingLeft:"0px",justifyContent:"center"}}>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
          </ul>
          <p style={{lineHeight:"26px"}}>My heart full gratitude to Doctor & I was glad to get the right treatment !! Even though medicine can cure but inspirational words from doctor can give the inner strength to fight from within.
Extremely cooperative staff. I would certainly recommend, this is the best clinic to get the right treatment for piles in Bengaluru.</p>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div style={{padding:"3px"}} className="reviewrr">
      <div style={{backgroundColor:"white",borderRadius:"5px",padding:"16px"}}>
        <div className="row">
        <div className="col-3">
          <img style={{borderRadius:"50%"}} src="../photo/letter-s.png" alt="" />
        </div>
        <div style={{display:'flex',alignItems:'center',padding:"0px"}} className="col-9">
          <div>
          <h5 style={{marginBottom:"0px"}}>sumant pradhan</h5>
          <p style={{marginBottom:"0px"}}>6 reviews
</p>
          </div>
        </div>
        </div>
        <div style={{marginTop:"0px",display:"flex",textAlign:"center",justifyContent:"center",height:"260px",overflow:"hidden",overflowY:"scroll",marginBottom:"20px"}}>
        <div>
        <ul style={{display:"flex",marginLeft:"0px",paddingLeft:"0px",justifyContent:"center"}}>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
            <li style={{width:"25px",padding:"3px"}}>
              <img src="../photo/star.png" alt="" />
            </li>
          </ul>
          <p style={{lineHeight:"26px"}}>One of the best place for piles treatment. Doctor is absolutely good. The hospital is very neat and clean.</p>
        </div>
        </div>
      </div>
    </div>
  </div>
</Carousel>
        </div>
        </div>
     <div style={{width:"100vw",display:"flex", marginTop:"30px",justifyContent:"center"}}>   
<a href='https://www.google.com/search?q=zain+piles+hospital&sca_esv=568723682&sxsrf=AM9HkKk3x1WJjnDkE5SNFnw2PcnpqcZYSQ%3A1695788506939&source=hp&ei=2q0TZZmjLuPv1e8Pnfuw4Aw&iflsig=AO6bgOgAAAAAZRO76gHlz7_wnMCzJwX-jVt4J4-w4srH&gs_ssp=eJzj4tVP1zc0LDE3yjYwyss2YLRSNagwTkpMNTQ0MjA3SjNJSzYztTKoSDWySDJISzVOTU0yNEg0svQSrkrMzFMoyMxJLVbIyC8uyCxJzAEAHEUWug&oq=zain+piles+&gs_lp=Egdnd3Mtd2l6Igt6YWluIHBpbGVzICoCCAAyERAuGK8BGMcBGJIDGMkDGIAEMgUQABiABDIFEAAYgAQyAhAmMgIQJjIIEAAYigUYhgMyCBAAGIoFGIYDMggQABiKBRiGAzIIEAAYigUYhgNIuDpQAFiVKnAAeACQAQCYAf8BoAHzDqoBBTAuOS4yuAEByAEA-AEBwgINEC4YigUYxwEYrwEYJ8ICFBAuGIMBGMcBGLEDGNEDGIoFGJECwgIUEC4YigUYsQMYgwEYxwEY0QMYkQLCAggQABiABBixA8ICCBAuGIAEGLEDwgILEAAYgAQYsQMYgwHCAhEQLhiABBixAxiDARjHARjRA8ICExAuGIoFGLEDGIMBGMcBGNEDGEPCAgcQLhiKBRhDwgIHEAAYigUYQ8ICBRAuGIAEwgILEC4YigUYsQMYgwHCAgoQABiKBRixAxhDwgIKEC4YigUYsQMYQ8ICDRAuGIoFGLEDGIMBGEPCAgsQLhiABBixAxiDAcICDRAuGIoFGMcBGNEDGEPCAgsQLhivARjHARiABMICCxAuGIAEGMcBGK8BwgIHEAAYgAQYCg&sclient=gws-wiz#lrd=0x3bae112072f4fc65:0xe28b0fe3eeb10a29,1,,,,' style={{backgroundColor:"#f22283",padding:"10px 20px",textDecoration:"none",color:"white",borderRadius:"20px"}} className="appointment">Read More</a></div>
      </div>
      
    </section>
            </>)
}
export default memo(Review);