import { memo, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

function Newheder() {
  const myref = useRef();
  const [scre, setScre] = useState();
  useEffect(() => {
    const bodyh = myref.current.offsetTop;
    const bodyhe = window.innerHeight;
    setScre(bodyhe + bodyh);
    console.log(bodyhe - bodyh);
  });
  const [truer, setTruer] = useState("none");
  const [activ, setActiv] = useState(true);

  const [truera, setTruera] = useState("none");
  const [activa, setActiva] = useState(true);

  const challen = {
    display: `${truer}`,
    // height:`${scre}px`
    height: "auto",
    marginLeft: "-300PX",
  };
  const abcd = {
    display: `${truera}`,
  };
  return (
    <>
      <header class="header header-layout1">
        <div style={{ backgroundColor: "#f4365f" }} class="header-topbar">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-12">
                <div class="d-flex align-items-center justify-content-between">
                  <ul class="contact__list d-flex flex-wrap align-items-center list-unstyled mb-0">
                    <li>
                      <button class="miniPopup-emergency-trigger" type="button">
                        Emergency
                      </button>
                      <div
                        id="miniPopup-emergency"
                        class="miniPopup miniPopup-emergency text-center"
                      >
                        <div class="emergency__icon">
                          <i class="icon-call3"></i>
                        </div>
                        <a
                          style={{ textDecoration: "none" }}
                          href="tel:8867724886"
                          class="phone__number"
                        >
                          <i class="icon-phone"></i> <span>8867724886 </span>
                        </a>
                        <p>
                          Please feel free to contact our friendly reception
                          staff with any general or medical enquiry.
                        </p>
                        <a
                          style={{ textDecoration: "none" }}
                          class="btn btn__secondary btn__link btn__block removete"
                        >
                          <span class="removete">Make Appointment</span>{" "}
                          <i class="icon-arrow-right"></i>
                        </a>
                      </div>
                    </li>
                    <li>
                      <i class="icon-phone"></i>
                      <a
                        style={{ textDecoration: "none" }}
                        href="tel:8867724886 "
                      >
                        Emergency Line: 8867724886{" "}
                      </a>
                    </li>
                    <li>
                      <i class="icon-location"></i>
                      <a style={{ textDecoration: "none" }}>
                        Location: Banaswadi, Bangalore
                      </a>
                    </li>
                    <li>
                      <i class="icon-clock"></i>
                      <a style={{ textDecoration: "none" }}>Mon - Sun: 24x7</a>
                    </li>
                  </ul>
                  <div class="d-flex">
                    <ul class="social-icons list-unstyled mb-0 mr-30">
                      <li>
                        <a
                          style={{ textDecoration: "none" }}
                          href="https://www.facebook.com/zainpileshospital/"
                        >
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          style={{ textDecoration: "none" }}
                          href="https://www.google.com/search?q=zain+piles+hospital&sca_esv=568723682&sxsrf=AM9HkKk3x1WJjnDkE5SNFnw2PcnpqcZYSQ%3A1695788506939&source=hp&ei=2q0TZZmjLuPv1e8Pnfuw4Aw&iflsig=AO6bgOgAAAAAZRO76gHlz7_wnMCzJwX-jVt4J4-w4srH&gs_ssp=eJzj4tVP1zc0LDE3yjYwyss2YLRSNagwTkpMNTQ0MjA3SjNJSzYztTKoSDWySDJISzVOTU0yNEg0svQSrkrMzFMoyMxJLVbIyC8uyCxJzAEAHEUWug&oq=zain+piles+&gs_lp=Egdnd3Mtd2l6Igt6YWluIHBpbGVzICoCCAAyERAuGK8BGMcBGJIDGMkDGIAEMgUQABiABDIFEAAYgAQyAhAmMgIQJjIIEAAYigUYhgMyCBAAGIoFGIYDMggQABiKBRiGAzIIEAAYigUYhgNIuDpQAFiVKnAAeACQAQCYAf8BoAHzDqoBBTAuOS4yuAEByAEA-AEBwgINEC4YigUYxwEYrwEYJ8ICFBAuGIMBGMcBGLEDGNEDGIoFGJECwgIUEC4YigUYsQMYgwEYxwEY0QMYkQLCAggQABiABBixA8ICCBAuGIAEGLEDwgILEAAYgAQYsQMYgwHCAhEQLhiABBixAxiDARjHARjRA8ICExAuGIoFGLEDGIMBGMcBGNEDGEPCAgcQLhiKBRhDwgIHEAAYigUYQ8ICBRAuGIAEwgILEC4YigUYsQMYgwHCAgoQABiKBRixAxhDwgIKEC4YigUYsQMYQ8ICDRAuGIoFGLEDGIMBGEPCAgsQLhiABBixAxiDAcICDRAuGIoFGMcBGNEDGEPCAgsQLhivARjHARiABMICCxAuGIAEGMcBGK8BwgIHEAAYgAQYCg&sclient=gws-wiz"
                        >
                          <i class="fab fa-google"></i>
                        </a>
                      </li>
                      <li>
                        <a style={{ textDecoration: "none" }} href="#">
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                    <form class="header-topbar__search">
                      {/* <input type="text" class="form-control" placeholder="Search..."/>
                    <button class="header-topbar__search-btn"><i class="fa fa-search"></i></button> */}
                      <marquee
                        direction="left"
                        style={{ marginBottom: "0px", color: "white" }}
                      >
                        best piles hospital in bangalore
                      </marquee>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav class="navbar navbar-expand-lg sticky-navbar">
          <div class="container-fluid">
            <Link to="/" class="navbar-brand">
              <img src="../photo/logos.png" class="logo-light" alt="logo" />
              <img src="../photo/logos.png" class="logo-dark" alt="logo" />
            </Link>
            <button
              onClick={() => {
                if (activ) {
                  setActiva(!activ);
                  setTruera("block");
                } else {
                  setActiva(!activ);
                  setTruera("none");
                }
              }}
              class="navbar-toggler alianjghhj"
              type="button"
            >
              <span class="menu-lines">
                <span></span>
              </span>
            </button>
            <div
              style={abcd}
              class="collapse navbar-collapse menu-opened"
              id="mainNavigation"
            >
              <ul class="navbar-nav ml-auto">
                <li
                  style={{ marginLeft: "30px" }}
                  class="nav__item has-dropdown"
                >
                  <Link
                    to="/"
                    style={{ textDecoration: "none" }}
                    data-toggle="dropdown"
                    class=" nav__item-link active"
                  >
                    Home
                  </Link>
                </li>
                <li
                  style={{ textDecoration: "none" }}
                  class="nav__item has-dropdown"
                >
                  <Link
                    to="/about"
                    style={{ textDecoration: "none" }}
                    data-toggle="dropdown"
                    class=" nav__item-link pinkis"
                  >
                    About
                  </Link>
                </li>
                <li
                  style={{ textDecoration: "none" }}
                  class="nav__item has-dropdown"
                >
                  <Link
                    to="/doctor"
                    style={{ textDecoration: "none" }}
                    data-toggle="dropdown"
                    class=" nav__item-link"
                  >
                    Doctors
                  </Link>
                </li>
                <li class="nav__item has-dropdown">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/blog"
                    data-toggle="dropdown"
                    class=" nav__item-link"
                  >
                    Blog
                  </Link>
                </li>
                <li class="nav__item has-dropdown">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/gallery"
                    data-toggle="dropdown"
                    class=" nav__item-link"
                  >
                    Gallery
                  </Link>
                </li>
                <li class="nav__item has-dropdown halava">
                  <Link
                    style={{ textDecoration: "none" }}
                    data-toggle="dropdown"
                    class=" nav__item-link "
                  >
                    Departments
                  </Link>

                  <ul
                    style={{ width: "100%" }}
                    id=""
                    class="miniPopup miniPopup-departments dropdown-menu active alianer"
                  >
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/pilestreatments"
                        class="nav__item-link"
                      >
                        Piles Treatments
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/pilessurgery"
                        class="nav__item-link"
                      >
                        Piles Surgery
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/analfissures"
                        class="nav__item-link"
                      >
                        Anal Fissures
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/analpolyps"
                        class="nav__item-link"
                      >
                        Anal Polyps
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/fistulaInano"
                        class="nav__item-link"
                      >
                        Fistula- In- Ano
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/rectalprolapse"
                        class="nav__item-link"
                      >
                        Rectal Prolapse
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/perianalabscess"
                        class="nav__item-link"
                      >
                        Perianal Abscess
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/analskintags"
                        class="nav__item-link"
                      >
                        Anal Skintags
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/ibs"
                        class="nav__item-link"
                      >
                        IBS
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/constipation"
                        class="nav__item-link"
                      >
                        Constipation
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/fistulasurgery"
                        class="nav__item-link"
                      >
                        Fistula Surgery
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/fistulatreatment"
                        class="nav__item-link"
                      >
                        Fistula Treatment
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/hemorrhoidstreatment"
                        class="nav__item-link"
                      >
                        Hemorrhoids Treatment
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/endoscopic"
                        class="nav__item-link"
                      >
                        EPST
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/miph"
                        class="nav__item-link"
                      >
                        MIPH
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/laser"
                        class="nav__item-link"
                      >
                        Laser Treatment For Piles
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/anal"
                        class="nav__item-link"
                      >
                        Anal Warts Treatments{" "}
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/chroniculcer"
                        class="nav__item-link"
                      >
                        Chronic Ulcer
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/infertility"
                        class="nav__item-link"
                      >
                        Infertility
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/circumcision"
                        class="nav__item-link"
                      >
                        Circumcision
                      </Link>
                    </li>
                    <li class="nav__item">
                      <Link
                        style={{ textDecoration: "none" }}
                        to="/sexproblems"
                        class="nav__item-link"
                      >
                        Sex Problems & Treatments{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li class="nav__item">
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/contact"
                    class="nav__item-link"
                  >
                    Contacts
                  </Link>
                </li>
              </ul>
              <button
                onClick={() => {
                  if (activ) {
                    setActiv(true);
                    setTruera("none");
                    console.log("close");
                  }
                }}
                class="close-mobile-menu d-block d-lg-none"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="d-none d-xl-flex align-items-center position-relative ml-30">
              <div
                onClick={() => {
                  if (activ) {
                    setActiv(!activ);
                    setTruer("block");
                  } else {
                    setActiv(!activ);
                    setTruer("none");
                  }
                }}
                class="miniPopup-departments-trigger"
              >
                <span
                  class="menu-lines"
                  id="miniPopup-departments-trigger-icon"
                >
                  <span></span>
                </span>
                <a style={{ textDecoration: "none" }}>Departments</a>
              </div>
              <ul
                ref={myref}
                style={challen}
                id=""
                class="miniPopup miniPopup-departments dropdown-menu active ulofscreener alinerliber"
              >
                <ul className="hilandrere">
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/pilestreatments"
                      class="nav__item-link"
                    >
                      Piles Treatments
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/pilessurgery"
                      class="nav__item-link"
                    >
                      Piles Surgery
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/analfissures"
                      class="nav__item-link"
                    >
                      Anal Fissures
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/analpolyps"
                      class="nav__item-link"
                    >
                      Anal Polyps
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/fistulaInano"
                      class="nav__item-link"
                    >
                      Fistula- In- Ano
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/rectalprolapse"
                      class="nav__item-link"
                    >
                      Rectal Prolapse
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/perianalabscess"
                      class="nav__item-link"
                    >
                      Perianal Abscess
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/analskintags"
                      class="nav__item-link"
                    >
                      Anal Skintags
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/ibs"
                      class="nav__item-link"
                    >
                      IBS
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/constipation"
                      class="nav__item-link"
                    >
                      Constipation
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/fistulasurgery"
                      class="nav__item-link"
                    >
                      Fistula Surgery
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/fistulatreatment"
                      class="nav__item-link"
                    >
                      Fistula Treatment
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/hemorrhoidstreatment"
                      class="nav__item-link"
                    >
                      Hemorrhoids Treatment
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/endoscopic"
                      class="nav__item-link"
                    >
                      Endoscopic Pilonidal Sinus Treatment
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/miph"
                      class="nav__item-link"
                    >
                      MIPH
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/laser"
                      class="nav__item-link"
                    >
                      Laser Treatment For Piles
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/anal"
                      class="nav__item-link"
                    >
                      Anal Warts Treatments{" "}
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/chroniculcer"
                      class="nav__item-link"
                    >
                      Chronic Ulcer
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/infertility"
                      class="nav__item-link"
                    >
                      Infertility
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/circumcision"
                      class="nav__item-link"
                    >
                      Circumcision
                    </Link>
                  </li>
                  <li class="nav__item">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/sexproblems"
                      class="nav__item-link"
                    >
                      Sex Problems & Treatments{" "}
                    </Link>
                  </li>
                </ul>
              </ul>
              <a
                href="https://click4appointment.com/book-appointment-guest/502168/3326"
                style={{ backgroundColor: "#f4365f", textDecoration: "none" }}
                class="btn btn__primary btn__rounded ml-30"
              >
                <i class="icon-calendar"></i>
                <span>Appointment</span>
              </a>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
export default memo(Newheder);
