import { memo } from "react"
import Servicelist from "../Servicelist"

function Constipationser(){
    return(<>
        <section class="blog blog-single pb-80 pt-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="post-item mb-0">
                  <div class="post__img">
                    <a href="#">
                      <img className="w-100" src="../photo/p9.jpg" alt="post image" loading="lazy"/>
                    </a>
                  </div>
                  <div  class="post__body pb-0">
                    <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                      <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                    </div>
                    <h1 class="post__title mb-30">
                    Constipation
                    </h1>
                    <div class="post__desc">
                      <p>Constipation is a common digestive issue characterized by infrequent bowel movements or difficulty passing stools. It occurs when the colon absorbs too much water from the stool, making it hard, dry, and difficult to pass. While the normal frequency of bowel movements varies from person to person, going three or fewer times per week is generally considered a sign of constipation.</p>
                      <p>There are several factors that can contribute to constipation, including a lack of dietary fiber, inadequate fluid intake, sedentary lifestyle, certain medications (e.g., opioids, antacids with aluminum or calcium), hormonal changes (e.g., during pregnancy), and certain medical conditions (e.g., irritable bowel syndrome, hypothyroidism). Ignoring the urge to have a bowel movement and changes in daily routines (e.g., traveling) can also lead to constipation.</p>
                      <p>The symptoms of constipation may include straining during bowel movements, feeling of incomplete evacuation, bloating, abdominal discomfort, and sometimes, small, hard stools. In severe cases or chronic constipation, individuals may experience complications such as anal fissures, hemorrhoids, or fecal impaction.</p>
                      <p>The treatment of constipation typically involves lifestyle changes and dietary modifications. Increasing fiber intake by consuming more fruits, vegetables, whole grains, and legumes can help soften the stool and promote regular bowel movements. Staying hydrated by drinking plenty of water is also crucial. Regular physical activity can stimulate bowel movements, so incorporating exercise into daily routines can be beneficial. If lifestyle changes are insufficient, over-the-counter laxatives may be used, but they should be used under the guidance of a healthcare professional to avoid dependency.</p>
                      <p>If constipation becomes chronic or is accompanied by severe symptoms, it's essential to seek medical attention to rule out any underlying medical conditions. In some cases, the doctor may recommend further evaluation or prescribe medications to address the specific cause of constipation and alleviate the symptoms.</p>
                    </div>
                  </div>
                </div>
              </div>
              
                    <Servicelist/>
            </div>
          </div>
        </section>
    </>)
}
export default memo(Constipationser)