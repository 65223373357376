import { memo } from "react"
import Servicelist from "../Servicelist"

function Fistulainanoser(){
    return (<>
        <section class="blog blog-single pb-80 pt-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="post-item mb-0">
                  <div class="post__img">
                    <a href="#">
                      <img className="w-100" src="../photo/p7.jpg" alt="post image" loading="lazy"/>
                    </a>
                  </div>
                  <div  class="post__body pb-0">
                    <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                      <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                    </div>
                    <h1 class="post__title mb-30">
                    Fistula- in- Ano
                    </h1>
                    <div class="post__desc">
                      <p>Fistula-in-ano, also referred to as an anal fistula, is a medical condition characterized by an abnormal tunnel or tract that develops between the anal canal or rectum and the skin surrounding the anus. It typically arises as a consequence of an anal abscess, which is a localized collection of pus in the tissues near the anus. When an abscess fails to heal properly or completely, it can lead to the formation of a fistula, causing significant discomfort and complications for the affected individual.</p>
                      <p>The condition presents with symptoms such as persistent pain around the anus, particularly aggravated during bowel movements, along with the discharge of pus or blood from an opening near the anus. Swelling, redness, and tenderness in the surrounding skin are also common, and fever and chills may indicate the presence of infection. Fistula-in-ano is diagnosed through physical examination, taking into account the patient's medical history, and often complemented with imaging tests to assess the extent and path of the fistula tract.</p>
                      <p>Treatment for fistula-in-ano typically involves surgical intervention, with the specific procedure chosen based on the complexity and location of the fistula. Common surgical options include fistulotomy, where the tract is laid open for internal healing, seton placement to promote gradual drainage and healing, and the use of fibrin glue or plugs to close the internal opening. Additionally, advancement flap procedures may involve using healthy tissue to close the external opening of the fistula. Proper post-surgical wound care and anal hygiene are crucial to prevent recurrence and ensure successful healing. If any symptoms of fistula-in-ano are suspected, seeking prompt medical attention is essential for an accurate diagnosis and appropriate treatment plan.</p>
                    </div>
                  </div>
                </div>
              </div>
              
                    <Servicelist/>
            </div>
          </div>
        </section>
    </>)
}
export default memo(Fistulainanoser)