import { memo } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";

function footer() {
  return (
    <>
      <footer
        style={{ padding: "0px", backgroundColor: "#7b0021" }}
        class="footer"
      >
        <div style={{ backgroundColor: "#7b0021" }} class="footer-primary">
          <div class="container">
            <div style={{ width: "100%" }} class="row">
              <div class=" col-lg-3">
                <div class="footer-widget-about">
                  <div
                    style={{
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="w-100 mb-30"
                  >
                    <img
                      style={{ width: "280px" }}
                      src="../photo/logos.png"
                      alt="logo"
                      class=""
                    />
                  </div>
                  <p class="color-gray">
                    Zain piles hospital is an advanced proctology centre with
                    all types and systems of treatment for piles
                    ,fissure,fistula in ano , ano rectal abscess, rectal
                    prolapse, constipation, anal warts, pilonidal cyst,
                    pilonidal abscess, pilonidal sinus and other conditions
                    related to the ano rectal region.
                  </p>
                  <a
                    href="https://click4appointment.com/book-appointment-guest/502168/3326"
                    style={{ backgroundColor: "yellow", color: "#7b0021" }}
                    class="btn btn__primary btn__link removete"
                  >
                    <span>Make Appointment</span>{" "}
                    <i class="icon-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-2 offset-lg-1">
                <div class="footer-widget-nav">
                  <h6 class="footer-widget__title">Departments</h6>
                  <nav>
                    <ul class="list-unstyled">
                      <li>
                        <Link
                          to="/pilestreatments"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Piles Treatments</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/pilessurgery"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Piles Surgery</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/analfissures"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Anal Fissures</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/analpolyps"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Anal Polyps</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/fistulaInano"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Fistula- in- Ano</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/rectalprolapse"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Rectal Prolapse</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/perianalabscess"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Perianal Abscess</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/analskintags"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Anal Skintags</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/constipation"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Constipation</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/fistulasurgery"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Fistula Surgery</strong>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-2">
                <div class="footer-widget-nav">
                  <h6 class="footer-widget__title">Links</h6>
                  <nav>
                    <ul class="list-unstyled">
                      <li>
                        <Link
                          to="/"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Home</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/about"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>About Us</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/doctor"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Doctors</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Gallery</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/pilestreatments"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Departments</strong>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/contact"
                          style={{ color: "white" }}
                          className="removete"
                        >
                          <strong>Contact Us</strong>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center" }}
                class="col-sm-12 col-md-6 col-lg-4"
              >
                <div class="footer-widget-contact">
                  <h6 class="footer-widget__title color-heading">
                    Quick Contacts
                  </h6>
                  <ul class="contact-list list-unstyled">
                    <li>
                      If you have any questions or need help, feel free to
                      contact with our team.
                    </li>
                    <li className="mt-2 mb-2">
                      <a
                        style={{
                          fontSize: "22px",
                          margin: "20px 0px",
                          color: "#7b0021",
                          fontWeight: "600",
                        }}
                        href="tel:8867724886 "
                        class=" removete"
                      >
                        <i class="icon-phone"></i> <span>8867724886 </span>
                      </a>
                    </li>
                    <li className="mt-2 mb-2">
                      <a
                        style={{
                          fontSize: "22px",
                          margin: "20px 0px",
                          color: "#7b0021",
                          fontWeight: "600",
                        }}
                        href="tel:9480394694"
                        class=" removete"
                      >
                        <i class="icon-phone"></i> <span>9480394694</span>
                      </a>
                    </li>
                    <li class="color-body">
                      ZAIN PILES HOSPITAL ,Near to Zamindar Eye Hospital ,10 Th
                      Main ,100 Ft Road ,HRBR LAYOUT,1st Block, Banaswadi,
                      Bangalore, Karnataka 560043
                    </li>
                  </ul>
                  <div class="d-flex align-items-center">
                    <a
                      href="https://click4appointment.com/book-appointment-guest/502168/3326"
                      style={{ backgroundColor: "yellow", color: "#7b0021" }}
                      class="btn btn__primary btn__link mr-30"
                    >
                      <i class="icon-arrow-right"></i>{" "}
                      <span>Get Directions</span>
                    </a>
                    <ul class="social-icons list-unstyled mb-0">
                      {/* <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                    <li><a href="#"><i class="fab fa-twitter"></i></a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: "20px" }} class="footer-secondary">
          <div class="container">
            <div class="row align-items-center">
              <div
                style={{ display: "flex" }}
                class="col-sm-12 col-md-6 col-lg-6"
              >
                <span class="fz-14">&copy; zain piles hospital design by </span>
                <a
                  style={{ color: "blue" }}
                  class="fz-14 removete"
                  href="https://appaddindia.net/"
                >
                  {" "}
                  appaddindia.com
                </a>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <nav>
                  <ul class="list-unstyled footer__copyright-links d-flex flex-wrap justify-content-end mb-0">
                    <li>
                      <a className="removete" href="#"></a>
                    </li>
                    <li>
                      <a className="removete" href="#"></a>
                    </li>
                    <li>
                      <a className="removete" href="#"></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default memo(footer);
