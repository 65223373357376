import React from 'react'
import { Link } from 'react-router-dom'

const Gallerybanner = () => {
  return (
    <>
    <section class="page-title page-title-layout1 bg-overlay gallerybanner">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5">
            <h1 style={{color:"white"}} class="pagetitle__heading">Gallery</h1>
            <p style={{color:"white"}} class="pagetitle__desc">We, Zain Piles & Skin Hospital situated at Banaswadi, Bangalore, Karnataka, are a well acclaimed piles Hospital providing a range of piles and other treatments.
            </p>
            <div class="d-flex flex-wrap align-items-center">
              <Link to="/" style={{backgroundColor:"#ffff00",color:"black"}} class="btn btn__primary btn__rounded mr-30">
                <span>Home</span>
                <i class="icon-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Gallerybanner
