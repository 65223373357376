import { memo } from "react"
import Servicelist from "../Servicelist"

function Pilessurgeryser(){
    return(<>
        <section class="blog blog-single pb-80 pt-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="post-item mb-0">
                  <div class="post__img">
                    <a href="#">
                      <img className="w-100" src="../photo/p2.jpg" alt="post image" loading="lazy"/>
                    </a>
                  </div>
                  <div  class="post__body pb-0">
                    <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                      <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                    </div>
                    <h1 class="post__title mb-30">
                    Piles Surgery 
                    </h1>
                    <div class="post__desc">
                      <p>Piles surgery, also known as hemorrhoidectomy, is a medical procedure performed to treat severe or persistent cases of piles (hemorrhoids). It is typically recommended when other conservative treatments have failed to provide relief or when the piles are causing significant discomfort, pain, and bleeding. The main goal of the surgery is to remove the swollen and inflamed hemorrhoidal tissue to alleviate symptoms and improve the patient's quality of life.</p>
                      <p>There are different surgical techniques used for piles surgery, and the specific method chosen may depend on the size, location, and severity of the hemorrhoids. Some common approaches include</p>
                      <h6 style={{fontWeight:"bold"}}>Conventional Hemorrhoidectomy</h6>
                      <p> In this traditional method, the surgeon removes the hemorrhoids using a scalpel or surgical scissors. The procedure is performed under anesthesia, and the patient is typically required to stay in the hospital for a short period for monitoring and pain management.</p>
                      <h6 style={{fontWeight:"bold"}}>Stapled Hemorrhoidopexy (PPH)</h6>
                      <p>Also known as procedure for prolapse and hemorrhoids (PPH), this technique involves using a circular stapler to remove excess hemorrhoidal tissue and reposition the remaining tissue back into its original position. It is often less painful and may have a shorter recovery time compared to conventional hemorrhoidectomy.</p>
                      <h6 style={{fontWeight:"bold"}}>Laser Hemorrhoidectomy</h6>
                      <p>This method utilizes a laser beam to vaporize or excise the hemorrhoids. Laser hemorrhoidectomy may cause less bleeding and is associated with reduced postoperative pain compared to conventional techniques.</p>
                      <h6 style={{fontWeight:"bold"}}>Doppler-Guided Hemorrhoidal Artery Ligation (DG-HAL)</h6>
                      <p>In this procedure, a special probe with a Doppler ultrasound is used to identify the arteries supplying blood to the hemorrhoids. The surgeon then ties off these arteries, reducing blood flow to the hemorrhoids, which causes them to shrink and eventually resolve.</p>
                      <p>As with any surgical procedure, piles surgery comes with certain risks and potential complications, including bleeding, infection, urinary retention, and temporary difficulty in controlling bowel movements. However, for many patients suffering from severe piles that significantly impact their daily life, surgery can be a highly effective and definitive treatment option.</p>
                      <p>It is essential for individuals considering piles surgery to have a thorough discussion with their healthcare provider to understand the risks, benefits, and expected outcomes of the specific surgical approach. Additionally, proper post-operative care and adherence to the surgeon's instructions are crucial for a successful recovery after piles surgery.</p>
                    </div>
                  </div>
                </div>
              </div>
              
                    <Servicelist/>
            </div>
          </div>
        </section>
    </>)
}
export default memo(Pilessurgeryser)