import { memo } from "react"
import { Link } from "react-router-dom"

function Chroniculcerbanner(){
    return(<>
        <section class="page-title page-title-layout1 bg-overlay piletratmentsbanner18">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5">
                <h1 style={{color:"white"}} class="pagetitle__heading">Chronic Ulcer</h1>
                <p style={{color:"white"}} class="pagetitle__desc">A chronic ulcer is a type of wound that fails to heal within the expected time frame, typically three months or more. These ulcers can occur on the skin or the mucous membranes and are often caused by various underlying conditions or factors that hinder the natural healing process.
                </p>
                <div class="d-flex flex-wrap align-items-center">
                  <Link to="/" style={{backgroundColor:"#ffff00",color:"black"}} class="btn btn__primary btn__rounded mr-30">
                    <span>Home</span>
                    <i class="icon-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>)
}
export default memo(Chroniculcerbanner)