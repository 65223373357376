import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Analpolypsbanner from "./Analpolypsbanner"
import Analfissuresser from "../Analfissures/Analfissuresser"
import Footer from "../../Footer/footer"
import Analpolypsser from "./Analpolypsser"
import { autotoper } from "../../Mainfunction"

function Analpolyps(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <Analpolypsbanner/>
    <Analpolypsser/>
    <Footer/>
    </>)
}
export default memo(Analpolyps)