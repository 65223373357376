import { memo } from "react"
import Servicelist from "../Servicelist"

function IBSser(){
    return(<>
        <section class="blog blog-single pb-80 pt-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="post-item mb-0">
                  <div class="post__img">
                    <a href="#">
                      <img className="w-100" src="../photo/p10.jpg" alt="post image" loading="lazy"/>
                    </a>
                  </div>
                  <div  class="post__body pb-0">
                    <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                      <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                    </div>
                    <h1 class="post__title mb-30">
                    IBS (Irritable Bowel Syndrome)
                    </h1>
                    <div class="post__desc">
                      <p>Irritable Bowel Syndrome (IBS) is a common functional gastrointestinal disorder that affects the large intestine (colon). It is characterized by a group of chronic symptoms related to bowel habits and abdominal discomfort. IBS is a functional disorder, meaning there are no structural abnormalities in the digestive tract, but the bowel does not function as it should. The exact cause of IBS is not fully understood, but it is believed to involve a combination of factors, including abnormal gut motility, sensitivity to certain foods, and heightened responses to stress.</p>
                      <p>The primary symptoms of IBS include abdominal pain or cramping, bloating, and changes in bowel habits. These changes can manifest as diarrhea, constipation, or alternating episodes of both. IBS can vary in severity, with some individuals experiencing mild discomfort, while others may have more frequent and intense symptoms that significantly impact their quality of life.</p>
                      <p>Diagnosis of IBS is made based on the patient's reported symptoms and the exclusion of other medical conditions through a comprehensive evaluation. There is no cure for IBS, but management primarily focuses on relieving symptoms and improving overall well-being. Lifestyle changes, such as dietary modifications (avoiding trigger foods, increasing fiber intake), stress management techniques, and regular exercise, can be beneficial in managing IBS. In more severe cases, a healthcare provider may recommend medications to alleviate specific symptoms, like antispasmodics for cramping or laxatives for constipation.</p>
                      <p>It's important to consult a healthcare professional if you suspect you have IBS or are experiencing persistent gastrointestinal symptoms. They can provide an accurate diagnosis, create an individualized management plan, and help improve the quality of life for individuals with IBS.</p>
                    </div>
                  </div>
                </div>
              </div>
              
                    <Servicelist/>
            </div>
          </div>
        </section>
    </>)
}
export default memo(IBSser)