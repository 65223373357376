import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Perianalabscessbanner from "./Perianalabscessbanner"
import Perianalabscessser from "./Perianalabscessser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function Perianalabscess(){
    useEffect(()=>{
        autotoper()
    },[])
    return(<>
    <Newheader/>
    <Perianalabscessbanner/>
    <Perianalabscessser/>
    <Footer/>
    </>)
}
export default memo(Perianalabscess)