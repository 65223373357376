import { memo, useEffect } from "react"
import Newheader from "../../Newheader/Newheader"
import Constipationbanner from "./Constipationbanner"
import Constipationser from "./Constipationser"
import Footer from "../../Footer/footer"
import { autotoper } from "../../Mainfunction"

function Constipation(){
    useEffect(()=>{
        autotoper()
    },[])
    return (<>
    <Newheader/>
    <Constipationbanner/>
    <Constipationser/>
    <Footer/>
    </>)
}
export default memo(Constipation)