import { memo } from "react"
import Servicelist from "../Servicelist"

function Circumcisionser(){
    return (<>
        <section class="blog blog-single pb-80 pt-80">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="post-item mb-0">
                  <div class="post__img">
                    <a href="#">
                      <img className="w-100" src="../photo/p20.jpg" alt="post image" loading="lazy"/>
                    </a>
                  </div>
                  <div  class="post__body pb-0">
                    <div style={{backgroundColor:"#ffff00"}} class="post__meta-cat">
                      <a style={{textDecoration:"none",backgroundColor:"#ffff00",color:"black"}} href="#">ZAIN PILES HOSPITAL</a>
                    </div>
                    <h1 class="post__title mb-30">
                    Circumcision
                    </h1>
                    <div class="post__desc">
                      <p>Circumcision is a surgical procedure that involves the removal of the foreskin, a fold of skin covering the head of the penis. This procedure is typically performed on male infants shortly after birth, but it can also be done during adolescence or adulthood for various cultural, religious, medical, or personal reasons.</p>
                      <p>The most common reasons for circumcision include religious or cultural beliefs, hygiene considerations, and potential medical benefits. Some religious traditions, like Judaism and Islam, consider circumcision a significant ritual. From a hygiene perspective, some people believe that circumcision may make it easier to keep the penis clean, reducing the risk of infections or other issues related to the accumulation of smegma.</p>
                      <p>Medical benefits of circumcision include a reduced risk of urinary tract infections (UTIs), certain sexually transmitted infections (STIs), and penile cancer. Circumcision has also been associated with a lower risk of HIV transmission in heterosexual men, particularly in regions with high HIV prevalence. However, it's important to note that the degree of protection provided by circumcision for some of these conditions varies, and safe sexual practices and proper hygiene remain essential for overall health.</p>
                      <p>The circumcision procedure involves numbing the area with a local anesthetic, followed by the removal of the foreskin using surgical instruments. The wound is typically closed with dissolvable stitches or adhesive glue, and healing usually takes around one to two weeks. Complications from circumcision are generally rare but can include infection, excessive bleeding, or cosmetic concerns.</p>
                      <p>The decision to undergo circumcision is a personal choice and may vary based on cultural, religious, or medical considerations. Parents often discuss the potential benefits and risks with their healthcare provider before making the decision for their infant. For older individuals seeking circumcision, a thorough discussion with a healthcare professional can help address any concerns and ensure informed consent before the procedure is performed.</p>
                    </div>
                  </div>
                </div>
              </div>
              
                    <Servicelist/>
            </div>
          </div>
        </section>
    </>)
}
export default memo(Circumcisionser)